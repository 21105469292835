import {createSlice} from "@reduxjs/toolkit";


const twodNumberList = createSlice({
     name: "chooseNumberLists",
     initialState: {
        value: []
     },
     reducers: {
        addNormal: (state,action) => {
            if(typeof action.payload === "string"){
                state.value = [action.payload,...state.value];
            }else{
                state.value = [...action.payload,...state.value];
            }
        },
        remove: (state,action) => {
            state.value = state.value.filter(el => el !== action.payload);
        },
        cleanValue: (state) => {
            state.value = [];
        }
     }
});

export const {addNormal,remove,cleanValue} = twodNumberList.actions;
export default twodNumberList.reducer;