import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {FiTrash2,FiEdit} from 'react-icons/fi';
import Button from "../utils/Button";
import { cleanValue, remove } from '../store/towdNumbersList';
import { getMethodToken, postMethodRaw } from '../Apis/Apis';
import { createPortal } from 'react-dom';
import Loading from '../utils/Loading';
import { cleanRValue } from '../store/twodRNumberList';
import { actionAmountStatus } from '../store/activeAmountDisable';
import { actionStatus } from '../store/activeRdisableAll';
import SubmitModal from '../utils/SubmitModal';
import { cleanNum } from '../store/parmuNumbersSlice';
import { cleanTotal, totalValue } from '../store/totalAmountSlice';
import { totalAmount } from '../store/amountSlice';
import { cleanSubmitValue } from '../store/submitNumberSlice';
import { useNavigate } from 'react-router-dom';

const ContainerDiv = styled.form`
    padding: 20px
`
const Table = styled.table`
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
    thead, tr, th, td {
        border: 1px solid #9d9d9d;
    }
    th, td {
        padding: 10px;

        button {
            border: none;
            padding: 0.375rem 0.75rem;
            border: 1px solid transparent;
            border-radius: 50%;
            font-size: 16px;
            margin-left: 10px;

            &:focus {
                outline: 0;
                box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
            }
        }
    }
    td {
        color: #FFF;
        font-size: 15px;
    }

    thead, tbody tr, tfoot {
        display: table;
        width: 100%;
        table-layout: fixed;
    }
    thead {
        -webkit-box-shadow: #00000063 0px 5px 5px;;
         box-shadow: #00000063 0px 5px 5px;;
    }
`
const MoneyP = styled.p`
    color: #d4e2ff;
`

const Tbody = styled.tbody`
    display: block;
    max-height: 45vh;
    overflow: hidden auto;
`

const TotalTable = () => {
    const [loading,setLoading] = useState(true);
    const [title,setTitle] = useState("");
    const [openBox,setOpenBox] = useState(false);
    const [timePath,setTimePath] = useState(false);
    const [message,setMessage] = useState("");
    const [fColor,setFcolor] = useState("");
    const [currentBalance,setCurrentBalance] = useState(0);
    const dispatch = useDispatch();
    const amountLength = useSelector(state => state.totallength.value);
    const amountR = useSelector(state => state.ramountValue.value);
    const towdLists = useSelector(state => state.twodNumberLists.value);
    const towdRLists = useSelector(state => state.twodRNumberList.value);
    const permuArrObj = useSelector(state => state.parmuNum.value);
    const totalamount = useSelector(state => state.total.value);
    const submitNumber = useSelector(state => state.submitNumber.value);
    const navigate = useNavigate();
    let temp = [];
    useEffect(()=> {
        if(towdRLists.length > 0){
            towdRLists.map(rnum => {
                dispatch(remove(rnum));
            });
        };
    },[]);

    if(permuArrObj.length > 0){
        temp = permuArrObj;
    }
   
    if(towdLists.length > 0){
        for (const i of towdLists){
            temp.push({number: i, amount: amountLength});
        };
        towdRLists.map(rNumber => {
            temp = [...temp,{number: rNumber, amount: amountR}];
        });
    };

    useEffect(() => {
        let am = 0
        if(temp.length > 0){
            temp.map(el => {
                am += Number(el.amount); 
            })
        };
        dispatch(totalValue(am));
    },[temp]);

    useEffect(() =>{
        let token = localStorage.getItem("token");
        if(token){
            const fetchData = async () => {
                const result = await getMethodToken("/user",token);
                if(result.message === "Unauthenticated."){
                    localStorage.removeItem("token");
                    navigate('/login');
                    console.clear();
                }else{
                    setCurrentBalance(result.wallet.balance);
                    setLoading(false);
                };
            };
            fetchData();
        }
    },[]);

    const submitResultHandler = async (e) => {
        e.preventDefault();
        let obj = {
           ...submitNumber,
           numbers: temp
        };
        setLoading(true);
        
        if(currentBalance >= totalamount){
            let token = localStorage.getItem("token");
            if(token){
                const response = await postMethodRaw('/bets',obj,token);
                if(response){
                    if(response.errors){
                        setTitle(response.message);
                        setMessage(response.message);
                        setFcolor("red");
                        setLoading(false);
                        setOpenBox(true);
                        setTimePath(false);
                    }else{
                        setTitle("Success");
                        setFcolor("green");
                        setMessage("Success");
                        setTimePath(true);
                        temp = [];
                        obj = {};
                        setLoading(false);
                        dispatch(cleanValue());
                        dispatch(cleanTotal());
                        dispatch(cleanRValue());
                        dispatch(actionAmountStatus(false));
                        dispatch(cleanSubmitValue());
                        dispatch(actionStatus(false));
                        dispatch(cleanNum());
                        dispatch(totalAmount(0));
                        setOpenBox(true);
                    };
                };
            };
        }else{
            setTitle("Error");
            setMessage("Current value is not enough");
            setFcolor("red");
            setLoading(false);
            setOpenBox(true);
            setTimePath(false);
        };         
    };    
    
    const chunk = num => {
        let str = num.toString().split('.');
        if (str[0].length >= 4) {
            str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        }
        if (str[1] && str[1].length >= 5) {
            str[1] = str[1].replace(/(\d{3})/g, '$1 ');
        }
        return str.join('.');
    };
  return (
    <>
        <ContainerDiv onSubmit={submitResultHandler}>
                <Table>
                    <thead>
                        <tr>
                            <th width="60px" style={{textAlign: 'right'}}>စဉ်</th>
                            <th style={{textAlign: 'center'}}>ကဏန်း</th>
                            <th style={{textAlign: 'right'}}>ငွေပမာဏ</th>
                        </tr>
                    </thead>
                    <Tbody>
                        {
                        temp.length > 0 &&  temp.map((val,index) => {
                                return  <tr key = {index}>
                                            <td width="60px" style={{textAlign: 'right'}}>{index + 1}</td>
                                            <td style={{textAlign: 'center'}}>{val.number}</td>
                                            <td style={{textAlign: 'right'}}>
                                                {val.amount ? chunk(String(val.amount)) : 0}
                                            </td>
                                        </tr> 
                            })
                        }
                    </Tbody>
                    <tfoot>
                        <tr>
                            <td width="60px" style={{textAlign: 'right'}}>#</td>
                            <td style={{color:"#fde34e",textAlign: 'center'}}>ထီထိုးငွေ</td>
                            <td style={{color:"#fde34e",textAlign: 'right'}}>{totalamount ? chunk(String(totalamount)) : 0}</td>
                        </tr>
                    </tfoot>
                </Table>
            <div>
                <MoneyP>လက်ကျန်ငွေ - {chunk(String(currentBalance))}</MoneyP>
                <Button type="submit" title = "ထိုးမည်" />
            </div>
        </ContainerDiv>
        {
            createPortal( loading && <Loading />, document.getElementById("portal"))
        }
        {
            createPortal( openBox && <SubmitModal title ={title} message = {message} close ={setOpenBox} timePathPoint = {timePath ? "/twod/time": null} color = {fColor} />, document.getElementById("portal"))
        }
    </>
  )
}

export default TotalTable