import React, { useEffect, useRef} from 'react'
import styled from 'styled-components';
import HeaderNav from './HeaderNav';
import Numbers from './Numbers';
import TwodFastChoose from './TwodFastChoose';
import SubmitNumber from './SubmitNumber';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import {addSubmitValue, cleanSubmitValue} from '../store/submitNumberSlice';

const NumberListContainer = styled.div`
   position: sticky;
   top: 0;
   z-index: 999;
`

const NumberList = () => {
  const usrId = useParams();
  const dispatch = useDispatch();
  const mounted = useRef(false);
  let data = {
    round_id: Number(usrId.id),
    bet_type: "Hundreds",
    time: moment().tz("Asia/Yangon").format("HH:mm:ss")
  };
  
  useEffect(()=>{
    dispatch(cleanSubmitValue());
    dispatch(addSubmitValue(data));
  },[]);
 
  useEffect(()=>{
    mounted.current = true;
    return () => {
        mounted.current = false;
    };
  },[]);
  
  return (
    <>
        <NumberListContainer>
            <HeaderNav type = "2D" />
            <TwodFastChoose id = {usrId.id} />
        </NumberListContainer>
        <Numbers />
        <SubmitNumber />
    </>
  )
}

export default NumberList;