import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { getMethodToken } from '../Apis/Apis';
import HeaderNav from './HeaderNav';
import ThreedTotalTable from './ThreedTotalTable';

const TimeH1 = styled.h1`
    margin-bottom: 0;
    padding: 20px 20px 0 20px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const ThreedTotal = () => {
  const currentDate = new Date();
  const [day,setDay] = useState(currentDate.toISOString().replace(/T.*/,'').split('-').join('-'));
  const [timeRes,setTimeRes] = useState("--:--");
  const id = useParams();
  const navigate = useNavigate();

  useEffect(()=> {
    let token = localStorage.getItem("token");
        if(token){
            const fetchData = async () => {
                const response = await getMethodToken(`/rounds`,token);
                if(response){
                  if(response.message === "Unauthenticated."){
                    localStorage.removeItem("token");
                    navigate('/login');
                    console.clear();
                  }else{
                    response.data.map(async el => {
                    if(el.id == id.id){
                        setTimeRes(timeConvert(el.name))
                      };
                    });
                  };
                };
            };
            fetchData();
    };
  },[]);

  function timeConvert (time) {
          time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
          if (time.length > 1) { 
          time = time.slice (1); 
          time[5] = +time[0] < 12 ? ' AM ' : ' PM ';
          time[0] = +time[0] % 12 || 12;
          };
          time.splice(3,1);
          return time.join ('');
  };

  return (
      <>
       <HeaderNav type = "3D" />
       <TimeH1>
          <span>{timeRes}</span>
          <span>{day}</span>
       </TimeH1>
       <ThreedTotalTable />
    </>
  )
}

export default ThreedTotal;
