import { createSlice } from "@reduxjs/toolkit";

const totalAmountSlice = createSlice({
    name: "Total",
    initialState: {
        value: 0,
    },
    reducers: {
        totalValue: (state,action) => {
            state.value = action.payload;
        },
        cleanTotal: state => {
            state.value = 0;
        }
    }
});

export const {totalValue,cleanTotal} = totalAmountSlice.actions;
export default totalAmountSlice.reducer;