import { createSlice } from "@reduxjs/toolkit";

const amountRSlice = createSlice({
    name: "Ramount",
    initialState: {
        value: 0
    },
    reducers: {
        ramoutAction: (state,action) => {
            state.value = action.payload;
        }
    }
});

export const {ramoutAction} = amountRSlice.actions;
export default amountRSlice.reducer;