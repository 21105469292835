import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from './Header';
import FooterNav from "./FooterNav";
import ProfileDetail from './ProfileDetail';
import { useNavigate } from 'react-router-dom';
import { getMethodToken } from '../Apis/Apis';
import loginUser from "../images/profile.png";
import walletImg from '../images/wallet.svg';
import { createPortal } from 'react-dom';
import Loading from '../utils/Loading';

const UserDetailDiv = styled.div`
    display: flex;
    align-items: center;
    padding: 0 20px 20px 20px;
    
    img {
        width: 50px !important;
        height: 50px !important;
        object-fit: cover;
        border-radius: 50%;
    }
    h5 {
        background-color: transparent;
        margin-left: 10px;
        margin-bottom: 0;
        font-size: 15px;
        color: #fff;
    }
`

const HeaderDivCard = styled.div`
   background-color: #FFF;
   padding: 10px 20px;
   color: #000 !important;
   display: flex;
   justify-content: space-between;
   align-items: center;
   border-radius: 10px;
   color: ${props => props.theme.primary};
`
const HeaderDiv = styled.div`
   padding: 0 20px 20px 20px;
`

const MoneySpan = styled.span `
    font-size: 15px;
    font-weight: 500;
    display: flex;
    justify-content: center;
`

const MoneyP = styled.p`
    margin-bottom: 0;
    font-weight: 600;
    margin-top: 5px;
`

const WalletImg = styled.img`
  width: 23px;
  margin-right: 7px;
`

const Profile = () => {
  const [name,setName] = useState("");
  const [wallet,setWallet] = useState(0);
  const [loading,setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect( () => {
      let token = localStorage.getItem("token");
      if(token){
        const fetchData = async () => {
          const result = await getMethodToken("/user",token);
          if(result){
            if(result.message === "Unauthenticated."){
              localStorage.removeItem("token");
              navigate('/login');
              console.clear();
            }else{
              setName(result.name);
              setWallet(result.wallet.balance);
              setLoading(false);
            };
          };
        };
        fetchData();
      };
  },[]);

  const chunk = num => {
    let str = num.toString().split('.');
    if (str[0].length >= 4) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
        str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
  };

  return (
    <>
        <Header />
        <UserDetailDiv>
            <img src={loginUser} className="shadow" alt="User" />
            <h5>{name}</h5>
        </UserDetailDiv>
        <HeaderDiv>
            <HeaderDivCard>
                <div>
                <MoneySpan>
                        <WalletImg src={walletImg} alt="wallet" />
                        လက်ကျန်ငွေ
                </MoneySpan>
                <MoneyP>{wallet ? chunk(String(wallet)) : 0}</MoneyP>
                </div>
            </HeaderDivCard>
        </HeaderDiv>
        <ProfileDetail />
        <FooterNav />
        {
          createPortal( loading && <Loading />, document.getElementById("portal"))
        }
    </>
  )
}

export default Profile;
