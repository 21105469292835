import React from 'react';
import {NavLink} from "react-router-dom";
import styled from 'styled-components';
import { FiFileText, FiAward } from "react-icons/fi";

const RecordDiv = styled.div`
   padding: 0px 20px 20px 20px;
`
const HistoryCard = styled.div`
   background-color: #ffffff36;
   border-radius: 10px;
`
const Ul = styled.ul`
   display: flex;
   justify-content: space-around;
   align-items: center;
`
const IconDiv = styled.div`
   padding: 10px 0;
   display: flex;
   flex-direction: column;
   align-items: center;

   span {
      font-size: 12px;
      margin-top: 6px;
   }
`
const listWidth = {
    width: "20%"
}

const RecordNav = ({id,bgColor,voucher,history,lotteryWinner,duringThelottery}) => {
  return (
    <RecordDiv style={{backgroundColor: bgColor}}>
        <HistoryCard>
            <Ul>
                <li style = {listWidth}>
                    <NavLink  to={`${voucher}/${id}`} style = {{color:"rgb(221 221 221)"}}>
                        <IconDiv>
                        <FiFileText size="24px" />
                        <span>ဘောင်ချာ</span>
                        </IconDiv>
                    </NavLink >
                </li>
                <li style = {listWidth}>
                    <NavLink  to={`${history}/${id}`} style = {{color:"rgb(221 221 221)"}}>
                        <IconDiv>
                        <FiFileText size="24px" />
                        <span>မှတ်တမ်း</span>
                        </IconDiv>
                    </NavLink >
                </li>
                <li style = {listWidth}>
                    <NavLink  to={`${lotteryWinner}/${id}`} style = {{color:"rgb(221 221 221)"}}>
                        <IconDiv>
                        <FiAward size="24px" />
                        <span>ထီပေါက်သူ</span>
                        </IconDiv>
                    </NavLink >
                </li>
                <li style = {listWidth}>
                    <NavLink  to={`${duringThelottery}/${id}`} style = {{color:"rgb(221 221 221)"}}>
                        <IconDiv>
                        <FiFileText size="24px" />
                        <span>ထီပေါက်စဉ်</span>
                        </IconDiv>
                    </NavLink >
                </li>
            </Ul>
        </HistoryCard>
    </RecordDiv>
  )
}

export default RecordNav;