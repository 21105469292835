import {createSlice} from "@reduxjs/toolkit";
const twodRNumberList = createSlice({
     name: "chooseNumberLists",
     initialState: {
        value: []
     },
     reducers: {
        addR: (state,action) => {
            state.value = [action.payload,...state.value];
        },
        removeR: (state,action) => {
            state.value = state.value.filter(el => el !== action.payload);
        },
        cleanRValue: (state) => {
            state.value = [];
        }
     }
});

export const {addR,removeR,cleanRValue} = twodRNumberList.actions;
export default twodRNumberList.reducer;