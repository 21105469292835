import React from 'react';
import {Link,useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import { FiFileText,FiKey } from "react-icons/fi";
import { AiOutlineTransaction } from "react-icons/ai";


const ProfileDetailParentCard = styled.div`
     padding: 0 20px 20px 20px;
`

const ProfileDetailChildCard = styled.div`
    background-color: #FFF;
    padding: 10px;
    color: #000 !important;
    border-radius: 10px;
    color: ${props => props.theme.primary};
`
const Li = styled.li`
  a {
    color: #343434;
    padding: 8px 10px;
    text-decoration: none;
    display: block;
    transition: 0.5s;
    border-radius: 5px;
    outline: none;
    &:hover {
        background-color: #0000001a;
    }
  }

  &:last-child {
    margin-top: 20px;
    text-align: center;
  }
`

const LogoutBtn = styled.button`
    border: none;
    padding: 10px 30px;
    background-color: ${props => props.theme.primary};
    color: #f5f5f5;
    outline: none;
    border-radius: 5px;
`

const ProfileDetail = () => {
  const navigate = useNavigate();
  let token = localStorage.getItem("token");
  const logoutHandler = () => {
     if(token){
        localStorage.removeItem("token");
        navigate('/login');
     }
  }
  return (
    <ProfileDetailParentCard>
        <ProfileDetailChildCard>
             <ul>
                <Li>
                    <Link to="change-password">
                       <FiKey size="20px" color="#01576a" style={{marginRight: "10px"}} />
                       လျို့ဝှက်နံပါတ် ပြောင်းမည်
                    </Link>
                </Li>
                <Li>
                    <Link to="/transaction-histories">
                       <AiOutlineTransaction size="20px" color="#01576a" style={{marginRight: "10px"}} />
                        ငွေမှတ်တမ်း
                    </Link>
                </Li>
                <Li>
                    <LogoutBtn type='button' onClick={logoutHandler}>ထွက်မည်</LogoutBtn>
                </Li>
             </ul>
        </ProfileDetailChildCard>
    </ProfileDetailParentCard>
  )
}

export default ProfileDetail
