import React from 'react';
import {Navigate} from "react-router-dom";

const RouteGuard = (props) => {
  let token = localStorage.getItem("token");
  if(token){
       return props.children; 
  }else{
       return <Navigate to = "/login" />;
  };
}

export default RouteGuard