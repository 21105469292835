import React from 'react';
import styled from 'styled-components';

const TableRow = styled.div`
   padding: 15px;
`

const TimeH5 = styled.h5`
    color: #d5dee8;
    font-size: 15pt;
    font-weight: 300;
    text-align: center;
    margin-bottom: 15px;    
`
const ModernCard = styled.div`
    margin: 60px 0 120px 0;
    border-radius: 10px;
    border: 2px solid ${props => props.theme.fontColor};
    background-color: #000000;
    background-image: linear-gradient(147deg, #000000 0%, #2c3e50 74%);
`
const MordenNumber = () => {
  return (
    <ModernCard>
        <TableRow>
            <div className="row">
                <div className="col">
                    <TimeH5 style={{marginTop: "10px"}}>00:00 AM</TimeH5>
                </div>
                <div className="col">
                    <span style={{color: "#FFF",fontSize: "14px",textAlign: "center",display: "block"}}>Modern</span>
                    <h4 style = {{color: "#fff",fontSize: "20px",padding: "0",margin: "3px auto",textAlign: "center"}}>0,0000</h4>
                </div>
                <div className="col">
                    <span style={{color: "#FFF",fontSize: "14px",textAlign: "right",display: "block"}}>Internet</span>
                    <h4 style = {{color: "#fff",fontSize: "20px",padding: "0",margin: "3px auto",textAlign: "right"}}>00</h4>
                </div>
            </div>
        </TableRow>
            <hr style = {{margin: 0}} />
        <TableRow>
            <div className="row">
                <div className="col">
                    <TimeH5 style={{marginTop: "10px"}}>00:00 AM</TimeH5>
                </div>
                <div className="col">
                    <span style={{color: "#FFF",fontSize: "14px",textAlign: "center",display: "block"}}>Modern</span>
                    <h4 style = {{color: "#fff",fontSize: "20px",padding: "0",margin: "3px auto",textAlign: "center"}}>0,0000</h4>
                </div>
                <div className="col">
                    <span style={{color: "#FFF",fontSize: "14px",textAlign: "right",display: "block"}}>Internet</span>
                    <h4 style = {{color: "#fff",fontSize: "20px",padding: "0",margin: "3px auto",textAlign: "right"}}>00</h4>
                </div>
            </div>
        </TableRow>
    </ModernCard>
  )
}

export default MordenNumber