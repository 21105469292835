import React, { useState } from 'react';
import Header from './Header';
import FooterNav from "./FooterNav";
import UserCash from '../utils/UserCash';
import { createPortal } from 'react-dom';
import Loading from '../utils/Loading';
import TopupWidthdrawNav from './TopupWidthdrawNav';
import TuturialCard from './TutorialCard';

const Wallet = () => {
  const [loading,setLoading] = useState(true);
  const [agentName, setAgentName] = useState("");

  const AgentDetail = data => {
      setAgentName(data.agent_username)
  };
  return (
    <>
        <Header />
          <UserCash setLoading = {setLoading} AgentDetail = {AgentDetail} />
          <TopupWidthdrawNav/>
          <TuturialCard/>
        <FooterNav />
        {
          createPortal( loading && <Loading />, document.getElementById("portal"))
        }
    </>
  )
}

export default Wallet
