import React from 'react';
import styled from 'styled-components';

const EmptyDiv = styled.div`
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
`

const EmptyRecord = ({message}) => {
  return (
    <EmptyDiv>
        {message}
    </EmptyDiv>
  )
}

export default EmptyRecord
