import React, { useState } from 'react';
import Header from './Header';
import FooterNav from "./FooterNav";
import { createPortal } from 'react-dom';
import Loading from '../utils/Loading';
import HeaderNav from './HeaderNav';
import WalletWidthdrawForm from './WalletWidthdrawForm';

const WalletTopup = () => {
  const [loading,setLoading] = useState(true);
  const [agentName, setAgentName] = useState("");

  const AgentDetail = data => {
      setAgentName(data.agent_username)
  };
  return (
    <>
        <Header />

        <WalletWidthdrawForm/>
        <FooterNav />
        {
        //   createPortal( loading && <Loading />, document.getElementById("portal"))
        }
    </>
  )
}

export default WalletTopup
