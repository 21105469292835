import React, { useState } from 'react';
import {useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import CloseTimeHeader from './CloseTimeHeader';
import HeaderNav from './HeaderNav';
import BannerSlide from "./BannerSlide";
import LiveNumber from './LiveNumber';
import TodayResult from './TodayResult';
import { createPortal } from 'react-dom';
import Loading from '../utils/Loading';

const HtoeBtn = styled.button`
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%,0);
    border: none;
    padding: 10px 35px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 10px;
    color: #4d2707;
    background-image: -webkit-linear-gradient(92deg,#fdd164,#daa520);
    &:focus {
        box-shadow: 0 0 0 2px #fbbf18;
    }
`


const Twod = () => {
  const [loading,setLoading] = useState(true);
  const navigate = useNavigate();
  return (
    <>
       <HeaderNav type = "2D" />
       <CloseTimeHeader setLoading = {setLoading} />
       <BannerSlide />
       {/* <LiveNumber/> */}
       <TodayResult />
       <HtoeBtn onClick={() => navigate("time")}>ထီထိုးမည်</HtoeBtn>
       {
        createPortal( loading && <Loading />, document.getElementById("portal"))
       }
    </>
  )
}

export default Twod;