import React,{useRef, useState} from 'react';
import Header from './Header';
import styled from 'styled-components';
import Passwordtoggle from '../utils/Passwordtoggle';
import { useNavigate } from 'react-router-dom';
import { postMethodRaw } from '../Apis/Apis';
import { createPortal } from 'react-dom';
import Loading from '../utils/Loading';
import SubmitModal from '../utils/SubmitModal';

const ChangepassDiv = styled.div`
   padding: 0 20px 20px 20px;
`

const FormTag = styled.form`
   display: flex;
   flex-direction: column;
   align-items: center;
`
const InputTag = styled.div`
   position: relative;
   margin-bottom: 20px;
   width: 80%;

   span {
     color: #c1c1c1;
     font-size: 14px;
     margin-bottom: 10px;
     display: block;
   }

   &:last-child {
      text-align: center;
      margin-bottom: 0;
   }
`

const ConfirmBtn = styled.button`
    border: none;
    padding: 10px 30px;
    background-color: ${props => props.theme.secondary};
    color: #f5f5f5;
    outline: none;
    border-radius: 5px;
    margin: 0 10px;
`

const Changepassword = () => {
  const [loading,setLoading] = useState(false);
  const [title,setTitle] = useState("");
  const [openBox,setOpenBox] = useState(false);
  const [timePath,setTimePath] = useState(false);
  const [message,setMessage] = useState("");
  const [fColor,setFcolor] = useState("");
  const navigate = useNavigate();
  const oldRef = useRef();
  const newRef = useRef();
  const confirmRef = useRef();

  const submitHandler = e => {
    e.preventDefault();
    setLoading(true);
    let data = {
      old_password: oldRef.current.value,
      new_password: newRef.current.value,
      new_password_confirmation: confirmRef.current.value
    };
    let token = localStorage.getItem("token");
        if(token){
            const fetchData = async () => {
                const result = await postMethodRaw("/change-password",data,token);
                if(result){
                  if(result.message === "Unauthenticated."){
                     localStorage.removeItem("token");
                     navigate('/login');
                     console.clear();
                 }else{
                     if(result.errors){
                      setTitle("Error");
                      setMessage(result.message);
                      setFcolor("red");
                      setLoading(false);
                      setOpenBox(true);
                      setTimePath(false);
                     }else{
                      setTitle("Success");
                      setFcolor("green");
                      setLoading(false);
                      setMessage(result.message);
                      setTimePath(true);
                      setOpenBox(true);
                      localStorage.removeItem("token");
                     }
                 };
                }
            };
            fetchData();
        };
  }
  return (
    <>
       <Header />
       <ChangepassDiv>
          <FormTag onSubmit={submitHandler}>
             <InputTag>
                <span>လျို့ဝှက်နံပါတ်အဟောင်းထည့်ပါ</span>
                <Passwordtoggle refValue = {oldRef} name = "password" placeholder="လျို့ဝှက်နံပါတ်အဟောင်းထည့်ပါ" />
             </InputTag>
             <InputTag>
                <span>လျို့ဝှက်နံပါတ်အသစ်ထည့်ပါ</span>
                <Passwordtoggle refValue = {newRef} name = "password" placeholder="လျို့ဝှက်နံပါတ်အသစ်ထည့်ပါ" />
             </InputTag>
             <InputTag>
                <span>လျို့ဝှက်နံပါတ်အသစ်အတည်ပြုပါ</span>
                <Passwordtoggle refValue = {confirmRef} name = "password" placeholder="လျို့ဝှက်နံပါတ်အသစ်အတည်ပြုပါ" />
             </InputTag>
             <InputTag>
                  <ConfirmBtn type='button' onClick={() => navigate(-1)}>နောက်သို့</ConfirmBtn>
                  <ConfirmBtn type='submit'>အတည်ပြု</ConfirmBtn>
             </InputTag>
          </FormTag>
       </ChangepassDiv>
        {
            createPortal( loading && <Loading />, document.getElementById("portal"))
        }
        {
            createPortal( openBox && <SubmitModal title ={title} message = {message} close ={setOpenBox} timePathPoint = {timePath ? "/login": null} color = {fColor} />, document.getElementById("portal"))
        }
    </>
  )
}

export default Changepassword
