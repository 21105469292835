import {createSlice} from '@reduxjs/toolkit';

const submitNumberSlice = createSlice({
     name: "submitNumber",
     initialState: {
        value: {},
     },
     reducers: {
        addSubmitValue : (state, action) => {
            state.value = action.payload;
        },
        cleanSubmitValue: (state) =>{
            state.value = {};
        }
     }
});

export const {addSubmitValue, cleanSubmitValue} = submitNumberSlice.actions;
export default submitNumberSlice.reducer;