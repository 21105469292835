import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import $ from 'jquery';
import '../styles/ThreedNumberWheel.css';
import ThreedNumberBtn from './ThreedNumberBtn';
import { addNums,cleanNums } from '../store/threedNumberSlice';
import { postMethodRaw } from '../Apis/Apis';
import { useNavigate, useParams } from 'react-router-dom';
import { createPortal } from 'react-dom';
import Loading from '../utils/Loading';


const ChooseNumberContainerDiv = styled.div`
     margin-top: 20px;
     padding-bottom: 100px;

     h5 {
       text-align: center;
       font-size: 16px;
       margin-bottom: 0;
     }
`

const NumbersDiv = styled.div`
    padding: 20px 0;
    display: flex;
    // justify-content: space-between;
    justify-content: center;
    flex-wrap: wrap;
`

const ThreedNumberWheel = () => {
  const threedNums = useSelector(state => state.threedNum.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false);
  const [threedBtns, setThreedBtns] = useState([]);
  let paramId = useParams();
  useEffect(()=>{  
      showNumberWheel();
  },[]);

  function permute(numbers){
    function perms(data) {
      if (!(data instanceof Array)) {
          throw new TypeError("input data must be an Array");
      };
      data = data.slice(); // make a copy
      var permutations = [],
          stack = [],
          hash = Object.create(null);
    
      function doPerm() {
          if (data.length == 0) {
              permutations.some(function (a) {
                  return a.every(function (b, j) {
                      return stack[j] === b;
                  });
              }) || permutations.push(stack.slice());
              return;
          }
          for (var i = 0; i < data.length; i++) {
              var x = data.splice(i, 1)[0];
              stack.push(x);
              doPerm();
              stack.pop();
              data.splice(i, 0, x);
          }
      }
    
      doPerm();
      return permutations;
    }
    
    var input = numbers;
    var result = perms(input);
    for (var i = 0; i < result.length; i++) {
      result[i] = result[i].join('');
    };
    // console.log(result)
    dispatch(addNums(result));
  }

  function showNumberWheel(){
    $(function () {
      var hub = window,
        gate = $(hub),
        cog = $(".reel"),
        niche = [0, 0, 0],
        beat;
    
      pulseMuzzle();
      tallyCells();
    
      if (document.readyState == "complete") interAction();
      else gate.one("load", interAction);
      let numb = document.querySelectorAll('.quota > div');
      numb.forEach(num => {
        let rotate = num.style.transform;
        if(rotate === "rotateX(0deg)"){
          num.style.color = "red";
        }
      });
      function interAction() {
        var heap = $(".quota"),
          field = $("#outcome"),
          range = cog.height(),
          ambit = heap.eq(0).find("div:first-child").height(),
          yaw = "mousemove.turf touchmove.turf",
          hike = "mouseup.limit touchend.limit",
          veer = "-webkit-transform",
          warp = "transform",
          unit = 180 / Math.PI,
          radius = 0,
          mound = [];
    
        cog.each(function (order) {
          var pinion = $(this).find(".quota"),
            slot = pinion.children(),
            ken = {};
    
          slot
            .each(function (i) {
              var aspect = Number(niche[order]) % 10 || 0;
    
              orbitSpin(this, ((i + aspect) * 36) % 360, true);
    
              if (!i && order == cog.length - 1) field.text(niche.join(""));
    
              if (!order && i && i < 3) {
                radius -= ambit * Math.sin((i * 36) / unit);
                if (i == 2) {
                  var axis = {},
                    pivot = "0 50% " + radius.toFixed(2) + "px";
                  axis[veer + "-origin"] = axis[warp + "-origin"] = pivot;
                  heap.css(axis);
                }
              }
            })
            .on("mousedown touchstart", function (e) {
              if (e.which && e.which != 1) return;
    
              if (pinion.hasClass("rotate")) {
                quietDown(pinion, slot, order);
                return false;
              }
    
              ken = mound[order] = {};
    
              tagPoints(cog[order], e, ken);
    
              gate
                .on(yaw, function (e) {
                  stalkHeart(e, ken);
                })
                .on(
                  yaw,
                  $.restrain(
                    40,
                    function () {
                      if (ken.lift) return;
    
                      slot.each(function () {
                        orbitSpin(this, ken);
                      });
                    },
                    true
                  )
                )
                .on(hike, function () {
                  lotRelease(order);
    
                  if (ken.gyre) driftAudit(slot, ken);
                });
    
              return false;
            });
    
          $(this)
            .on("wheel", function (e) {
              e.originalEvent.deltaY < 0 ? (ken.gyre = 36) : (ken.gyre = -36);
              return false;
            })
            .on(
              "wheel",
              $.restrain(40, function () {
                if (pinion.hasClass("rotate") && !pinion.hasClass("device"))
                  return false;
    
                pinion.addClass("device");
                revolveWheel(slot, "250ms", ken);
              })
            );
        });
    
        heap.on(beat, function () {
          var item = $(this);
          if (item.hasClass("settle")) item.removeClass("settle rotate");
          else if (item.hasClass("rotate")) assayFlow(this);
          else return;
          
          field.text(niche.join(""));
          let temp = [];
          let tempResult = [];
          let amuntArr = 0;
          niche.map(el => {
            if(el === "စီးရီး"){
              el = [0,1,2,3,4,5,6,7,8,9];
            };
            temp.push(el);
          });
          let findString = temp.some(el => typeof el === "object");
          if(findString){
            permute([]);
            tempResult = [];
            for(let i = 0;i < temp.length; i++){
                if(typeof temp[i] === "object"){
                  amuntArr++;
                }
            };
            if(amuntArr > 1){
              permute([]);
              temp = [];
              tempResult = [];
              dispatch(addNums([""]))
            }else{
              let findArr = temp.find(el => typeof el === "object");
              let replace = temp.findIndex(x => typeof x === "object");
              for(let i = 0; i < findArr.length; i++){
                 temp[replace] = findArr[i];
                 tempResult.push(temp.join(""));
              };
              dispatch(addNums(tempResult));
            };
        
          }else{
            permute(temp);
          };
          numb.forEach(num => {
            num.style.color = "#000";
            let rotate = num.style.transform
            if(rotate === "rotateX(0deg)"){
              num.style.color = "red";
            }
          });
        });
    
        function tagPoints(motif, task, bin) {
          var nod = task.originalEvent.touches,
            weigh = setDigit($(motif).offset().top);
    
          bin.rise = nod ? nod[0].pageY : task.pageY;
          bin.mark = bin.rise - weigh;
    
          isCue(bin);
        }
    
        function stalkHeart(act, jar) {
          var peg = act.originalEvent.touches,
            aim = peg ? peg[0].pageY : act.pageY,
            ilk = aim - jar.rise,
            due = Date.now(),
            base = Object.keys(jar.cast).length;
    
          jar.cap = Math.max(-jar.mark, Math.min(ilk, range - jar.mark));
          jar.gyre = setDigit(nookRatio(jar.cap));
          jar.cast[jar.poll] = [ilk, due];
    
          if (base) {
            var ante = jar.cast[(jar.poll + base - 1) % base];
            if (due != ante[1]) {
              jar.flux[jar.poll] = ilk - ante[0];
              jar.urge[jar.poll] = due - ante[1];
              jar.poll = (jar.poll + 1) % 10;
            }
          } else jar.poll = (jar.poll + 1) % 10;
    
          clearTimeout(jar.wipe);
          jar.wipe = setTimeout(isCue, 80, jar);
        }
    
        function isCue(tub) {
          tub.cast = {};
          tub.flux = [];
          tub.urge = [];
          tub.poll = 0;
        }
    
        function orbitSpin(piece, bend, keep) {
          var shim = $(piece),
            locus = shim.closest(cog).index(),
            mode = {};
    
          if (!$.isNumeric(bend)) bend = shim.data("angle") + bend.gyre;
          if (!bend || bend == 360) niche[locus] = shim.data("count");
    
          mode[veer] = mode[warp] = "rotateX(" + bend + "deg)";
          shim.css(mode);
    
          if (keep) shim.data("angle", bend);
        }
    
        function quietDown(tooth, oriel, sign) {
          if (tooth.hasClass("settle")) return;
    
          assayFlow(tooth);
    
          var edge = oriel.data("angle") % 36;
    
          if (!edge) return;
          else if (edge < 18) edge = -edge;
          else edge = 36 - edge;
    
          mound[sign].gyre = edge;
          var tempo = 15 * Math.abs(edge) + "ms";
    
          setTimeout(revolveWheel, 0, oriel, tempo, mound[sign]);
          tooth.addClass("settle");
        }
    
        function driftAudit(vent, bay) {
          var rush = checkPace(vent, bay),
            lean = bay.gyre,
            tilt = Math.abs(lean % 36),
            step = (lean - (lean % 36)) / 36;
    
          if (rush) var speed = rush;
          else {
            if (tilt < 18) {
              var notch = tilt;
              bay.gyre = step * 36;
            } else {
              notch = 36 - tilt;
              if (lean > 0) bay.gyre = (step + 1) * 36;
              else bay.gyre = (step - 1) * 36;
            }
            speed = Math.round(15 * notch);
          }
    
          revolveWheel(vent, speed + "ms", bay);
        }
    
        function checkPace(realm, hod) {
          if (hod.urge.length < 2) return;
    
          var info = hod.urge;
    
          if (!info[0]) {
            hod.flux.shift();
            info.shift();
          }
    
          var bulk = info.length,
            chunk = 0,
            whole = 0,
            mean =
              (1 / bulk) *
              info.reduce(function (total, cipher) {
                return total + cipher;
              }, 0),
            quirk = Math.min(
              0.75 * mean,
              (1.5 / bulk) *
                info.reduce(function (total, cipher) {
                  return total + Math.abs(cipher - mean);
                }, 0)
            );
    
          $.each(info, function (i) {
            if (this > mean + quirk || this < mean - quirk) return;
    
            chunk += hod.flux[i];
            whole += this;
          });
    
          mean = Math.abs(nookRatio(chunk) / whole);
          var cusp = hod.gyre,
            torque = ((realm.data("angle") + cusp) % 36) - cusp;
    
          if (Math.abs(chunk) < 7 || mean < 4e-2) return;
    
          if (chunk < 0) hod.gyre = 360 - torque;
          else hod.gyre = -324 - torque;
    
          return Math.round(Math.abs((hod.gyre - cusp) / mean));
        }
    
        function revolveWheel(leaf, haste, flask) {
          var cycle = {
            "-webkit-transition-duration": haste,
            "transition-duration": haste
          };
    
          leaf
            .parent()
            .css(cycle)
            .addClass("rotate")
            .end()
            .each(function () {
              orbitSpin(this, flask, true);
            });
        }
    
        function assayFlow(vial) {
          var slant;
    
          $(vial)
            .find("div:not(.quota)")
            .each(function (i) {
              if (!i) {
                var morph = $(this).css(warp) || $(this).css(veer),
                  rate = morph.replace(/[^0-9\-.,]/g, "").split(",");
    
                if (rate.length == 6) slant = 0;
                else
                  slant = Math.round(
                    Math.atan2(Number(rate[6]) || 0, rate[5]) * unit
                  );
                if (slant < 0) slant += 360;
              } else slant += 36;
    
              orbitSpin(this, setDigit(slant % 360), true);
            })
            .end()
            .removeClass("rotate device");
        }
    
        function lotRelease(fix) {
          gate.off(yaw + " " + hike);
          mound[fix].lift = true;
        }
    
        function setDigit(score) {
          return Math.round(score * 1e2) / 1e2;
        }
    
        function nookRatio(arc) {
          return Math.atan(arc / radius) * unit;
        }
      }
    
      function tallyCells() {
        cog.each(function () {
          for (var i = 0; i < 11; i++) {
            var n;
            i ? (n = 11 - i) : (n = 0);
            if(n === 10) n = "စီးရီး"
            $(this)
              .append("<div></div>")
              .find("div")
              .eq(i)
              .text(n)
              .data("count", n);
    
            if (i == 9) $(this).children().wrapAll('<div class="quota"></div>');
          }
        });
      }
    
      function pulseMuzzle() {
        var tick = "TransitionEvent";
    
        beat =
          tick in hub
            ? "transitionend"
            : "WebKit" + tick in hub
            ? "webkitTransitionEnd"
            : "";
    
        $.restrain = function (delay, rouse, hind) {
          var enact = 0,
            back;
    
          return function () {
            var lapse = Math.min(delay, Date.now() - enact),
              remain = delay - lapse;
            clearTimeout(back);
            lapse == delay && runIt();
    
            if (hind && remain) back = setTimeout(runIt, remain);
    
            function runIt() {
              enact = Date.now();
              rouse.apply(this, arguments);
            }
          };
        };
      }
    });
  };
  useEffect(()=>{
    let token = localStorage.getItem("token");
        if(token){
            const fetchData = async () => {
                  if(threedNums.length > 0){
                    setLoading(true);
                    const response = await postMethodRaw(`/three_d_numbers?round_id=${paramId.id}`,{numbers:threedNums},token);
                    if(response.message === "Unauthenticated."){
                        localStorage.removeItem("token");
                        navigate('/login');
                        console.clear();
                    }else{  
                        setThreedBtns(response.data);
                        setLoading(false);
                    };
                  }
            };
            fetchData();
        }
  },[threedNums]);
  return (
       <>
          <div id="machine">
            <div className="reel"></div>
            <div className="reel"></div>
            <div className="reel"></div>
          </div>
          <ChooseNumberContainerDiv>
               <h5>ထိုးမည့် ဂဏန်းရွေးပါ</h5>
               <NumbersDiv>
                  {
                     threedBtns.length > 0 && 
                     threedBtns.map(num => <ThreedNumberBtn number = {num.number} key = {num.id} status = {num.off} percentage = {num.percentage} />)
                  }
               </NumbersDiv>
          </ChooseNumberContainerDiv>
          {
              createPortal( loading && <Loading />, document.getElementById("portal"))
          }
       </>
  );
};

export default ThreedNumberWheel;
