import React from 'react';
import styled from 'styled-components';
import logo from '../images/logo/elite.svg';
// import logo from "../images/logo/Elite_Logo.png";

const HeaderDiv = styled.div`
   padding: 20px 20px 20px 20px;
   display:flex;
   justify-content: center;
   align-items: center;

   img {
     width: 70px;
   }
   button {
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 30px;
      color: #FFF;
      position: absolute;
      right: 20px;

      div {
        position: relative;
      }

      span {
        font-size: 12px;
        background-color: red;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items:center;
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: -5px;
      }
   }
`

const Header = () => {

  return (
    <HeaderDiv>
        <img src = {logo} alt="Logo" />
    </HeaderDiv>
  )
}

export default Header;