import {createSlice} from "@reduxjs/toolkit";

const activeAmountDisable = createSlice({
     name: "toggle",
     initialState: {
        value: false
     },
     reducers: {
        actionAmountStatus: (state,action) => {
            state.value = action.payload;
        }
     }
});

export const {actionAmountStatus} = activeAmountDisable.actions;
export default activeAmountDisable.reducer;