import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import MordenNumber from './MordenNumber';
import { useNavigate } from 'react-router-dom';
import { getMethodToken } from '../Apis/Apis';
import { addSetValue } from '../store/twodSetValueSlice';
import { addSetEveValue } from '../store/twodSetEveValueSlice';

const ResultDiv = styled.div`
   padding: 20px;
`

const TableCard = styled.div`
   border-radius: 10px;
   border: 2px solid ${props => props.theme.fontColor};
   margin-bottom: 15px;
`

const TableRow = styled.div`
   padding: 15px;
`

const TimeH5 = styled.h5`
    color: #d5dee8;
    font-size: 15pt;
    font-weight: 300;
    text-align: center;
    margin-bottom: 15px;    
`
const ResultNumber = styled.h4`{
    font-family: 'Roboto', sans-serif;
    background-image: -webkit-linear-gradient(92deg,#fdd164,#daa520);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    user-select: none;
    font-size: 20px;
    padding: 0;
    margin: 3px auto;
    text-align: right;
    font-weight: bolder;
}`

const bg1 = {backgroundColor: "rgba(255, 255, 255, 0.2)"};
const bg2 = {backgroundColor: "rgb(0 0 0 / 56%)"};
const bg3 = {backgroundColor: "rgba(255, 255, 255, 0.2)"};
const bg4 = {backgroundColor: "rgb(0 0 0 / 56%)"};

const TodayResult = () => {
  const setValue = useSelector(state => state.twodSetValue.value);
  const setEveValue = useSelector(state => state.twodSetEveValue.value);
  const dispatch = useDispatch();
  let navigate = useNavigate();
    const [morning,setMorning] = useState({});
    const [evening,setEvening] = useState({});

  useEffect(()=>{
    let token = localStorage.getItem("token");
    if(token){
        const fetchData = async () => {
            const response = await getMethodToken(`/rounds?game_type=2D`,token);
                if(response){
                    if(response.message === "Unauthenticated."){
                        localStorage.removeItem("token");
                        navigate('/login');
                        console.clear();
                    }else{
                        if(response.data.length > 0){
                            response.data.map(el => {
                                if(el.name === "12:01 PM"){
                                    setMorning(el);
                                }else if(el.name === "4:30 PM"){
                                    setEvening(el);
                                };
                            })
                        }
                    };
                };
        };
        fetchData();
    };
  },[]);

  useEffect(()=>{
    const isEmptyMorning = Object.keys(setValue).length === 0;
    if(isEmptyMorning){
        dispatch(addSetValue(morning));
    };
    const isEmptyEvening = Object.keys(setEveValue).length === 0;
    if(isEmptyEvening){
        dispatch(addSetEveValue(evening));
    };
  },[morning,evening])

  return (
    <ResultDiv>
        <TableCard style = {bg1}>
            <TableRow>
                <TimeH5>12:01 PM</TimeH5>
                <div className="row">
                    <div className="col">
                        <span style={{color: "#FFF",fontSize: "14px"}}>Set</span>
                        <h4 style = {{color: "#fff",fontSize: "20px",padding: "0",margin: "3px auto"}}>{setValue.set? setValue.set : '--'}</h4>
                    </div>
                    <div className="col">
                        <span style={{color: "#FFF",fontSize: "14px",textAlign: "center",display: "block"}}>Value</span>
                        <h4 style = {{color: "#fff",fontSize: "20px",padding: "0",margin: "3px auto",textAlign: "center"}}>{setValue.value? setValue.value: '--'}</h4>
                    </div>
                    <div className="col">
                        <span style={{color: "#FFF",fontSize: "14px",textAlign: "right",display: "block"}}>2D</span>
                        <ResultNumber>{setValue.result? setValue.result : '--'}</ResultNumber>
                    </div>
                </div>
             </TableRow>
        </TableCard>
        <TableCard style = {bg2}>
            <TableRow>
                <TimeH5>04:31 PM</TimeH5>
                <div className="row">
                    <div className="col">
                        <span style={{color: "#FFF",fontSize: "14px"}}>Set</span>
                        <h4 style = {{color: "#fff",fontSize: "20px",padding: "0",margin: "3px auto"}}>{setEveValue.set? setEveValue.set : '--'}</h4>
                    </div>
                    <div className="col">
                        <span style={{color: "#FFF",fontSize: "14px",textAlign: "center",display: "block"}}>Value</span>
                        <h4 style = {{color: "#fff",fontSize: "20px",padding: "0",margin: "3px auto",textAlign: "center"}}>{setEveValue.value? setEveValue.value: '--'}</h4>
                    </div>
                    <div className="col">
                        <span style={{color: "#FFF",fontSize: "14px",textAlign: "right",display: "block"}}>2D</span>
                        <ResultNumber>{setEveValue.result? setEveValue.result : '--'}</ResultNumber>
                    </div>
                </div>
             </TableRow>
        </TableCard>
        <TableCard style ={bg3}>
            <TableRow>
                <TimeH5>10:35 AM</TimeH5>
                <div className="row">
                    <div className="col">
                        <span style={{color: "#FFF",fontSize: "14px"}}>Set</span>
                        <h4 style = {{color: "#fff",fontSize: "20px",padding: "0",margin: "3px auto"}}>0,0000</h4>
                    </div>
                    <div className="col">
                        <span style={{color: "#FFF",fontSize: "14px",textAlign: "center",display: "block"}}>Value</span>
                        <h4 style = {{color: "#fff",fontSize: "20px",padding: "0",margin: "3px auto",textAlign: "center"}}>0,0000</h4>
                    </div>
                    <div className="col">
                        <span style={{color: "#FFF",fontSize: "14px",textAlign: "right",display: "block"}}>2D</span>
                        <ResultNumber>00</ResultNumber>
                    </div>
                </div>
             </TableRow>
        </TableCard>
        <TableCard style ={bg4}>
            <TableRow>
                <TimeH5>02:45 PM</TimeH5>
                <div className="row">
                    <div className="col">
                        <span style={{color: "#FFF",fontSize: "14px"}}>Set</span>
                        <h4 style = {{color: "#fff",fontSize: "20px",padding: "0",margin: "3px auto"}}>0,0000</h4>
                    </div>
                    <div className="col">
                        <span style={{color: "#FFF",fontSize: "14px",textAlign: "center",display: "block"}}>Value</span>
                        <h4 style = {{color: "#fff",fontSize: "20px",padding: "0",margin: "3px auto",textAlign: "center"}}>0,0000</h4>
                    </div>
                    <div className="col">
                        <span style={{color: "#FFF",fontSize: "14px",textAlign: "right",display: "block"}}>2D</span>
                        <ResultNumber>00</ResultNumber>
                    </div>
                </div>
             </TableRow>
        </TableCard>
        {/* -----------------Model Result---------------- */}
        <MordenNumber />

    </ResultDiv>
  )
}

export default TodayResult