import React, { useState } from 'react';
import BannerSlide from "./BannerSlide";
import Header from "./Header";
import LoginUserName from './LoginUserName';
import ListsRow from "./ListsRow";
import FooterNav from "./FooterNav";
import { createPortal } from 'react-dom';
import Loading from '../utils/Loading';

const Main = () => {
  const [loading,setLoading] = useState(true);
  return (
    <>
        <Header />
        <LoginUserName setLoading = {setLoading} />
        <BannerSlide />
        <ListsRow />
        <FooterNav />
        {
          createPortal( loading && <Loading />, document.getElementById("portal"))
        }
    </>
  )
}

export default Main;