const URL = "https://family2d3d.com/api";
// const domain = process.env.REACT_APP_DOMAIN;
const domain = "https://api.demo23.com/api";
// const domain = "http://api.controlunit.test/api";

export const getMethod = async (route) => {
    try {
        let res = await fetch(`${domain}${route}`);
        let resData = await res.json();
        return resData;
    }
    catch(err) {
        console.log(err.message);
    };
};

export const getMethodToken = async (route,token) => {
    try {
        let res = await fetch(`${domain}${route}`,{
            method: "GET",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                "Authorization" : `Bearer ${token}`,
            }
        });
        let resData = await res.json();
        return resData;
    }
    catch(err) {
        console.log(err.message);
    };
};

export const postMethod = async (route,data,token) => {
    try{
        let res = await fetch(`${domain}${route}`,{
            method: "POST",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                "Authorization" : `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        console.clear();
        let resData = await res.json();
        return resData;
    }
    catch(err) {
        console.log(err.message);
    };
};


export const postMethodRaw = async (route,data,token) => {
    try{
        let res = await fetch(`${domain}${route}`,{
            method: "POST",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                "Authorization" : `Bearer ${token}`,
            },
            body:  JSON.stringify(data)
        });
        let resData = await res.json();
        return resData;
    }
    catch(err) {
        console.log(err.message);
    };
};