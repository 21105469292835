import React from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import { motion } from "framer-motion";
import {FiX} from "react-icons/fi";

const ModalDiv = styled.div`
    position: fixed;
    z-index: 9999;
    background-color: rgba(7,14,7,.8);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .modalCard {
        width: 440px;
        background-color: #FFF;
        border-radius: 20px;
        padding: 20px 30px;
        overflow: hidden auto;
    }
`
const ModalTitle = styled.p`
   color: #444;
   text-align: center;
   margin-bottom: 10px;
   position: relative;
   font-size: 17px;


   button {
      border: none;
      font-size: 25px;
      background-color: transparent;
      cursor: pointer;
      position: absolute;
      right: -10px;
      bottom: 0;
      color: #444;
   }
`
const ModalCloseBtn = styled.button`
      padding: 10px 30px;
      border: none;
      background-color: #42DF00;
      border-radius: 10px;
      color: #FFF;
`
const SubmitModal = ({title,close,message,color,timePathPoint}) => {
  const navigate = useNavigate();
  return (
    <ModalDiv onClick={() => timePathPoint ? navigate(timePathPoint) : close(false)}>
        <motion.div className='modalCard' onClick={e => e.stopPropagation()}
           initial = {{opacity: 0,y: -50}}
           animate = {{
             opacity: 1,
             transition: {
                duration: 0.5
             },
             y: 0
           }}
          >
            <ModalTitle>{title} <button onClick={() => timePathPoint ? navigate(timePathPoint) : close(false)}><FiX /></button></ModalTitle>
            <p style={{marginBottom:"0",textAlign: "center",color: color}}>{message}</p>
            <div style={{marginTop: '10px',display: 'flex', justifyContent: 'center', alighItems: 'center'}}>
               <ModalCloseBtn onClick={() => timePathPoint ? navigate(timePathPoint) : close(false)}>Ok</ModalCloseBtn>
            </div>
         </motion.div>
    </ModalDiv>
  )
}

export default SubmitModal