import React, { useState } from 'react';
import Header from './Header';
import FooterNav from "./FooterNav";
import { createPortal } from 'react-dom';
import Loading from '../utils/Loading';
import TopupHistoryTable from './TopupHistoryTable';
import styled from 'styled-components';
import WidthdrawHistoryTable from './WidthdrawHistoryTable';
import TransitionStatusTabs from './TransitionStatusTabs';


const Ul = styled.ul`
   display: flex;
   justify-content: space-around;
   align-items: center;
   color: #fff;

   li{
    text-align: center;
    cursor: pointer;
    padding-bottom: 10px;
    width: 33.33%;
   }

   .active-tab{
    border-bottom: 1px #fff solid;
   }
`

const TopupWidthdrawHistory = () => {
  const [loading,setLoading] = useState(true);
  const [activeTab,setActiveTab] = useState('topup');
  const [agentName, setAgentName] = useState("");

  const AgentDetail = data => {
      setAgentName(data.agent_username)
  };

  const handleTabClick = (value) =>{
    setLoading(true);
    setActiveTab(value);
  }

  return (
    <>
        <Header />
          <Ul>
            <li onClick={() => handleTabClick('topup')} className={activeTab == 'topup'?'active-tab':''}>ငွေသွင်း</li>
            <li onClick={() => handleTabClick('widthdraw')} className={activeTab == 'widthdraw'?'active-tab':''}>ငွေထုတ်</li>
            <li onClick={() => handleTabClick('all')} className={activeTab == 'all'?'active-tab':''}>မှတ်တမ်း</li>
          </Ul>
          { activeTab == 'topup' && <TopupHistoryTable setLoading = {setLoading}/> }
          { activeTab == 'widthdraw' && <WidthdrawHistoryTable setLoading={setLoading}/> }
          { activeTab == 'all' && <TransitionStatusTabs setLoading={setLoading}/> }
        <FooterNav />
        {
          createPortal( loading && <Loading />, document.getElementById("portal"))
        }
    </>
  )
}

export default TopupWidthdrawHistory
