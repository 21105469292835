import React,{useState,useEffect} from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import {useSelector,useDispatch} from "react-redux";
import styled from 'styled-components';
import { actionStatus } from '../store/activeRdisableAll';
import { toggleRaction } from '../store/actionRbtn';
import {actionAmountStatus} from '../store/activeAmountDisable';
import { cleanTotal } from '../store/totalAmountSlice';
import {getMethodToken} from '../Apis/Apis';

const HtoeMyeDiv = styled.div`
    color: #FFF;
    font-size: 13px;
    display: flex;
    margin-bottom: 0;
    background-color: ${props => props.theme.secondary};
    padding: 10px 20px;
    position: fixed;
    width: 480px;
    left: 50%;
    margin-left: -240px;
    bottom: 0;
    justify-content: space-between;
    align-items: center;
    z-index: 999;

    @media (min-width: 421px) and (max-width: 460px) {
      width: 440px;
      left: 50%;
      margin-left: -220px;
    }

    @media (min-width: 401px) and (max-width: 420px) {
      width: 420px;
      left: 50%;
      margin-left: -210px;
    }

    @media (min-width: 351px) and (max-width: 400px) {
      width: 360px;
      left: 50%;
      margin-left: -180px;
    }

    @media (min-width: 340px) and (max-width: 350px) {
      width: 350px;
      left: 50%;
      margin-left: -175px;
    }
    

    button {
        padding: 10px 20px;
        font-size: 16px;
        border: none;
        border-radius: 10px;
        background-image: -webkit-linear-gradient(92deg,#fdd164,#daa520);
        color: #4d2707;
        &:focus {
            box-shadow: 0 0 0 2px #fbbf18;
        }
     }
`

const SubmitNumber = () => {
  const navigate = useNavigate();
  const towdLists = useSelector(state => state.twodNumberLists.value);
  const amountLength = useSelector(state => state.totallength.value);
  const permuArrObj = useSelector(state => state.parmuNum.value);
  const dispatch = useDispatch();
  const [min,setMin] = useState(0);
  const [max,setMax] = useState(0);
  const [leftHours,setLeftHours] = useState("00");
  const [leftMinutes,setLeftMinutes] = useState("00");
  const [leftSecond, setLeftSecond] = useState("00");
  const [endTime, setEndTime] = useState("");
  let id = useParams();

  useEffect(()=>{
    let token = localStorage.getItem("token");
    if(token){
        const fetchData = async () => {
            const response = await getMethodToken(`/rounds/${id.id}`,token);
            if(response){
              if(response.message === "Unauthenticated."){
                localStorage.removeItem("token");
                navigate('/login');
                console.clear();
              }else{
                  const result = await getMethodToken(`/settings?type=${response.data.type}`,token);
                  if(response.data.end_time){
                    setEndTime(response.data.end_time);
                  };
                  if(result){
                    if(result.data){
                      setMin(result.data[0].min_per_bet);
                      setMax(result.data[0].max_per_bet);
                    };
                  };
              };
            };
        };
        fetchData();
    };
  },[]);
  
  useEffect(()=>{
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    today = mm + '/' + dd + '/' + yyyy;

    let end = new Date(`${today} ${endTime}`);
    let _second = 1000;
    let _minute = _second * 60;
    let _hour = _minute * 60;
    let _day = _hour * 24;
    let timer;

    function showRemaining() {
        let now = new Date();
        let distance = end - now;
        if (distance < 0) {
            clearInterval(timer);
            return;
        }
        let days = Math.floor(distance / _day);
        let hours = Math.floor((distance % _day) / _hour);
        let minutes = Math.floor((distance % _hour) / _minute);
        let seconds = Math.floor((distance % _minute) / _second);
        setLeftHours((hours<10?'0':'') + hours);
        setLeftMinutes((minutes<10?'0':'') + minutes);
        setLeftSecond((seconds<10? '0':'') + seconds);
    };
    timer = setInterval(showRemaining, 1000);
  },[endTime])

  const submitHandler = () => {
    if(permuArrObj.length > 0){
      dispatch(cleanTotal());
      navigate("total");
      return;
    };
    if(towdLists.length > 0 && amountLength >= min) {
      if(amountLength < max){
        dispatch(actionStatus(false));
        dispatch(cleanTotal());
        dispatch(toggleRaction(true));
        dispatch(actionAmountStatus(false));
        navigate("total");
      }
    }else{
      alert(`Minium amount is ${min}`);
    };
  };


  return (
    <HtoeMyeDiv>
        <span>ထီပိတ်ချိန် : {leftHours}:{leftMinutes}:{leftSecond}</span>
        <button onClick={submitHandler}>ထီထိုးမည်</button>
    </HtoeMyeDiv>
  )
}

export default SubmitNumber