import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import NumberBtn from './NumberBtn';
import {getMethodToken} from '../Apis/Apis';
import { createPortal } from 'react-dom';
import Loading from '../utils/Loading';

const NumbersDiv = styled.div`
    padding: 20px 20px 70px 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`

const Numbers = () => {
  const [numberBtn, setNumberBtn] = useState([]);
  const [loading,setLoading] = useState(true);
  const navigate = useNavigate();
  let id = useParams();

  useEffect(()=> {
    let token = localStorage.getItem("token");
        if(token){
            const fetchData = async () => {
                const response = await getMethodToken(`/two_d_numbers?round_id=${id.id}`,token);
                if(response){
                  if(response.message === "Unauthenticated."){
                    localStorage.removeItem("token");
                    navigate('/login');
                    console.clear();
                  }else{
                      setNumberBtn(response.data);
                      setLoading(false);
                  };
                }
            };
            fetchData();
    };
},[]);

  return (
    <>
      <NumbersDiv>
          {
              numberBtn.map(num => <NumberBtn number = {num.number} key = {num.id} status = {num.off} percentage = {num.percentage} />)
          }
      </NumbersDiv>
      {
            createPortal( loading && <Loading />, document.getElementById("portal"))
      }
    </>
  )
}

export default Numbers;