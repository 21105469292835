import React from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import closeImg from '../images/close.png';

const ContainerDiv = styled.div`
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
`
const CloseImg = styled.img`
    width: 300px;
`
const H5 = styled.h5`
   font-size: 25px;
   margin-top: 20px;
   color: #FFF;
   margin-bottom: 25px;
`
const Button = styled.button`
   padding: 10px 25px;
   border: none;
   border-radius: 10px;
`

const Close = () => {
  const navigate = useNavigate();
  return (
    <ContainerDiv>
       <div style={{textAlign: "center"}}>
          <CloseImg src = {closeImg} alt ="close" />
          <H5>စနေ ၊ တနင်္ဂနွေ ထီပိတ်ပါသည်</H5>
          <Button onClick={()=> navigate(-1)}>OK</Button>
       </div>
    </ContainerDiv>
  )
}

export default Close
