import React,{useRef, useState} from 'react';
import { createPortal } from 'react-dom';
import {useNavigate} from "react-router-dom";
import styled from 'styled-components';
import LoginImg from '../images/login_background.png';
import Button from '../utils/Button';
import Input from '../utils/Input';
import { postMethod } from '../Apis/Apis';
import Loading from '../utils/Loading';

const LoginDiv = styled.div`
   min-height: inherit;
   display: flex;
   flex-direction: column;
`;

const LoginImgDiv = styled.div`
   width: 100%;
   height: 250px;
   background: url(${LoginImg}) no-repeat center;
   background-size: contain;
`

const Form = styled.form`
    padding-top: 35px;
    width: 340px;
    margin: 0 auto;
`
  
  function setWithExpiry(key, value, ttl) {
    localStorage.setItem(key, value);

  };

const Login = () => {
  const userName = useRef();
  const password = useRef();
  const [userNameError,setUserNameError] = useState("");
  const [userPassError,setUserPassError] = useState("");
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();
  
  const submitHandler = async (event) => {
    setLoading(true);
    event.preventDefault();
    let data = {
        username: userName.current.value,
        password: password.current.value
    };

   const response = await postMethod("/login",data,"");
       if(response){
          if(response.access_token){
            setWithExpiry("token",response.access_token,3600000);
            let getToken = localStorage.getItem("token");
              if(getToken){
                    setLoading(false);
                    navigate("/");
                    setUserNameError("");
                    setUserPassError("");
              };
            }else{
              if(response.message){
                setUserNameError(response.message);
                setUserPassError(response.message);
                setLoading(false);
              };
            };
       };
  };
  
  return (
    <>
       <LoginDiv>
          <LoginImgDiv></LoginImgDiv>
          <Form onSubmit={submitHandler}>
              <Input type="text" label="User Name" mb="20px" errorMsg = {userNameError} setErrorMsg = {setUserNameError} setRef = {userName} />
              <Input type="password" label="Password"  mb="20px" errorMsg = {userPassError} setErrorMsg = {setUserPassError}  setRef = {password} />
              <Button title="လော့အင်ဝင်မယ်" type="submit" />
          </Form>
      </LoginDiv>
      {
        createPortal( loading && <Loading />, document.getElementById("portal"))
      }
    </>
  )
}

export default Login;