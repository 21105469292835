import React, { useEffect, useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import HeaderNav from './HeaderNav';
import {getMethodToken} from '../Apis/Apis';
import EmptyRecord from '../utils/EmptyRecord';
import { createPortal } from 'react-dom';
import Loading from '../utils/Loading';

const TimeListBtn = styled.button`
    color: #444;
    font-size: 16px;
    letter-spacing: 0.5px;
    border: 1px solid #a3a3a3;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
    width: 100%;
    outline: none;
    background-color: #FFF;
    margin-bottom: 10px;
    transition: 0.5s;
    display:flex;
    align-items:center;
    justify-content: center;

    &:hover {
       background-color: ${props => props.theme.secondary};
       color: #FFF;
    }

    &:focus {
        background-color: ${props => props.theme.primary};
        color: #FFF;
    }

    span {
        margin-left: 40px;
        font-weight: bolder;
    }
`
const TimeUlist = styled.ul`
    padding: 20px;
`

const TimeList = ({type,filt}) => {
    const [rounds, setRounds] = useState([]);
    const [loading,setLoading] = useState(true);
    const [length, setLength] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        let token = localStorage.getItem("token");
        if(token){
            const fetchData = async () => {
                const response = await getMethodToken(`/rounds?game_type=${filt}`,token);
                if(response){
                    if(response.message === "Unauthenticated."){
                        localStorage.removeItem("token");
                        navigate('/login');
                        console.clear();
                    }else{  
                        setRounds(response.data);
                        setLoading(false);
                        if(response.data.length > 0){
                            setLength(true);
                        }else{
                            setLength(false);
                        }
                    };
                };
            };
            fetchData();
        };
    },[]);
    function timeConvert (time) {
        time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        if (time.length > 1) { 
          time = time.slice (1); 
          time[5] = +time[0] < 12 ? ' AM ' : ' PM ';
          time[0] = +time[0] % 12 || 12;
        };
        time.splice(3,1);
        return time.join ('');
      };
  return (
    <>
       <HeaderNav type = {type} />
       <TimeUlist>
            {
                length > 0 ? rounds.map(round => 
                    <li key = {round.id}>
                        <Link to={filt === "2d"? `number-list/${round.id}`: `number-list/${round.id}`}>
                           <TimeListBtn>{filt === "2d"?timeConvert(round.name) : timeConvert(round.name)}<span>{round.result? round.result : "--"}</span></TimeListBtn>
                        </Link>
                    </li>):
                <EmptyRecord message ="ယနေ့အတွက် Round မရှိပါ" />
            }
        </TimeUlist>
        {
            createPortal( loading && <Loading />, document.getElementById("portal"))
        }
    </>
  )
}

export default TimeList