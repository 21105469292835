import React, { useState } from 'react';
import Header from './Header';
import FooterNav from "./FooterNav";
import { createPortal } from 'react-dom';
import Loading from '../utils/Loading';
import TransitionStatusTabs from './TransitionStatusTabs';

const TransactionHistory = () => {
  const [loading,setLoading] = useState(true);
  const [agentName, setAgentName] = useState("");

  const AgentDetail = data => {
      setAgentName(data.agent_username)
  };
  return (
    <>
        <Header />
          <TransitionStatusTabs setLoading = {setLoading}/>
        <FooterNav />
        {
          createPortal( loading && <Loading />, document.getElementById("portal"))
        }
    </>
  )
}

export default TransactionHistory
