import {configureStore} from "@reduxjs/toolkit";
import actionRbtn from "./actionRbtn";
import activeAmountDisable from "./activeAmountDisable";
import activeRdisableAll from "./activeRdisableAll";
import amountRSlice from "./amountRSlice";
import amountSlice from "./amountSlice";
import fastChooseAlertSlice from "./fastChooseAlertSlice";
import parmuNumbersSlice from "./parmuNumbersSlice";
import threedNumberList from "./threedNumberList";
import threedNumberSlice from "./threedNumberSlice";
import totalAmountSlice from "./totalAmountSlice";
import towdNumbersList from "./towdNumbersList";
import twodRNumberList from "./twodRNumberList";
import submitNumberSlice from "./submitNumberSlice";
import twodSetValueSlice from "./twodSetValueSlice";
import twodSetEveValueSlice from "./twodSetEveValueSlice";

export const store = configureStore({
    reducer: {
        totallength: amountSlice,
        rAction: activeRdisableAll,
        amountAction: activeAmountDisable,
        twodNumberLists: towdNumbersList,
        twodRNumberList: twodRNumberList,
        toggleR: actionRbtn,
        ramountValue: amountRSlice,
        fastChooseValue: fastChooseAlertSlice,
        parmuNum: parmuNumbersSlice,
        total: totalAmountSlice,
        threedNum: threedNumberSlice,
        threedList: threedNumberList,
        submitNumber: submitNumberSlice,
        twodSetValue: twodSetValueSlice,
        twodSetEveValue: twodSetEveValueSlice,
    }
});