import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import Loading from '../utils/Loading';
import HeaderTitle from './HeaderTitle';
import WinnerLists from './WinnerLists';
const Winner = ({type}) => {
  const [loading, setLoading] = useState(true);
  return (
    <>
      <HeaderTitle message={`${type} ထိပ်ဆုံးဆုရှင်စာရင်း`} />
      <WinnerLists setLoading={setLoading} />
      {
            createPortal( loading && <Loading />, document.getElementById("portal"))
      }
    </>
  )
}

export default Winner
