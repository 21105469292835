import React, {useState,useRef,useEffect} from 'react';
import { createPortal } from 'react-dom';
import {useSelector,useDispatch} from "react-redux";
import { Link,useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import ModalBox from '../utils/ModalBox';
import RecordNav from "./RecordNav";
import {motion} from 'framer-motion';
import { totalAmount } from '../store/amountSlice';
import { actionStatus } from '../store/activeRdisableAll';
import { actionAmountStatus } from '../store/activeAmountDisable';
import { addNormal ,cleanValue, remove } from '../store/towdNumbersList';
import { addR, cleanRValue } from '../store/twodRNumberList';
import { toggleRaction } from '../store/actionRbtn';
import {ramoutAction} from '../store/amountRSlice';
import {changeFastChooseValue} from '../store/fastChooseAlertSlice';
import walletImg from '../images/wallet.svg';
import { getMethodToken } from '../Apis/Apis';
import Loading from '../utils/Loading';

const FastChooseDiv = styled.div`
   height: 65px;
   background-color: ${props => props.theme.secondary};
   padding: 0 20px 20px 20px;
   display: flex;
   align-items: center;
   justify-content: space-between;

   div {
        display: flex;
        align-items: center;
    }

   img {
    width: 25px;
    margin-right: 5px;
    }
    span {
        color: #FFF;
        font-size: 17px;
    }
   
   button {
      border: none;
      background-color: #d4e2ff;
      color: ${props => props.theme.primary};
      padding: 10px;
      border-radius: 10px;
      &:focus {
         box-shadow: 0 0 0 1px ${props => props.theme.secondary};
      }
   }

   @media (min-width: 340px) and (max-width: 350px) {
        span {
            color: #FFF;
            font-size: 14px;
        }
        button {
            font-size: 14px;
        }
   }
   @media (min-width: 351px) and (max-width: 400px) {
        span {
            color: #FFF;
            font-size: 14px;
        }
        button {
            font-size: 14px;
        }
   }
`

const ParmuSpan = styled.span`
        border: none;
        background-color: #d4e2ff;
        color: ${props => props.theme.primary};
        padding: 10px;
        border-radius: 10px;
        margin-left: 10px;
        &:focus {
           box-shadow: 0 0 0 1px ${props => props.theme.secondary};
        }
`

const TotalDiv = styled.div`
   display:flex;
   justify-content: space-between;
   align-items: flex-start;
   padding: 0 20px 20px 20px;
   background-color: ${props => props.theme.secondary};

   input {
        width: 100%;
        outline: none;
        border-radius: 10px;
        border: none;
        height: 100%;
        border-color: #c0c4cc;
        font-size: 18px;
        text-align: right;
        color: #606266;
        padding: 10px;

        &[type=checkbox]{
            display:none;
        }
    }
    label {
        padding: 10px;
        width:60px;
        border: none;
        border-radius: 10px;
        margin: 0 10px;
        height: 47px;
        font-weight: bold;
        font-size: 18px;
        background-color: #FFF;
        color: #000;
        text-align: center;
        user-select: none;
        cursor: pointer;
    }
`

const FastChooseH4 = styled.h4`
    color: ${props => props.theme.primary};
    font-size: 18px;
    margin-bottom: 8px;
    text-decoration: underline;
`
const SelectBtn = styled.button`
    padding: 5px;
    border:1px solid #8d8d8d;
    border-radius: 5px;
    box-shadow: 1px 1px 2px #adadad;
    font-size: 20px;
    width: 100%;
    color: ${props => props.theme.primary};
`

const LineThrough = styled.hr`
    color: ${props => props.theme.primary};
    margin: 23px 0;
`
const TwodFastChoose = ({id}) => {
   const [showModal,setShowModal] = useState(false);
   const [styling,setStyling] = useState({width: 0, opacity: 0, padding: 0});
   const [labelActive, setLabelActive] = useState({background: "#FFF",color: "#00cfff"})
   const dispatch = useDispatch();
   const checkedRef = useRef();
   const amountRef = useRef();
   const amountActionBtn = useSelector(state => state.amountAction.value);
   const towdLists = useSelector(state => state.twodNumberLists.value);
   const towdRLists = useSelector(state => state.twodRNumberList.value);
   const toggleR = useSelector(state => state.toggleR.value);
   const fastChoosebol = useSelector(state => state.fastChooseValue.value);
   const [numberBtn, setNumberBtn] = useState([]);
   const [ramout,setRamount] = useState(0);
   const [wallet,setWallet] = useState("");
   const [loading,setLoading] = useState(true);
   const [min,setMin] = useState(0);
   const [max,setMax] = useState(0);
   const navigate = useNavigate();
   let paramId = useParams();
   

   useEffect(() => {
        let token = localStorage.getItem("token");
        if(token){
            const fetchData = async () => {
                    const result = await getMethodToken("/user",token);
                    if(result){
                        if(result.message === "Unauthenticated."){
                            localStorage.removeItem("token");
                            navigate('/login');
                            console.clear();
                        }else{
                            setWallet(result.wallet.balance);
                            setLoading(false);
                        };
                    };
                    const response = await getMethodToken(`/two_d_numbers?round_id=${paramId.id}`,token);
                    if(response){
                        if(response.message === "Unauthenticated."){
                            localStorage.removeItem("token");
                            navigate('/login');
                            console.clear();
                        }else{
                            setNumberBtn(response.data);
                            setLoading(false);
                        };
                    };
                };
            fetchData();
        }
    },[]);

    useEffect(() =>{
        let token = localStorage.getItem("token");
        if(token){
            const fetchData = async () => {
                const response = await getMethodToken(`/rounds?game_type=2D`,token);
                if(response){
                    if(response.message === "Unauthenticated."){
                        localStorage.removeItem("token");
                        navigate('/login');
                        console.clear();
                    }else{  
                        response.data.length > 0 && 
                        response.data.map(async el => {
                            if(el.id == paramId.id){
                                const result = await getMethodToken(`/settings?type=${el.type}`,token);
                                if(result.data){
                                    setMin(result.data[0].min_per_bet);
                                    setMax(result.data[0].max_per_bet);
                                }
                                setLoading(false);
                            }
                        })
                        setLoading(false);
                    };
                };
            };
            fetchData();
        }
    },[]);
   const modalHandler = () => {
      setShowModal(true);
   };
   const lengthHandler = (e) => {
      if(Number(e.target.value) < min){
        dispatch(toggleRaction(true));
        return;
      };
      if(Number(e.target.value) > max){
        dispatch(toggleRaction(true));
        return;
      }
      if(Number(e.target.value)%min !== 0){
         dispatch(toggleRaction(true));
         return;
      };
      dispatch(totalAmount(e.target.value));
      setRamount(e.target.value);
      dispatch(ramoutAction(e.target.value));
      if(towdLists.length > 0 && e.target.value.length > 0){
        dispatch(toggleRaction(false));
      }else{
        dispatch(toggleRaction(true));
      };
   };
   
   const checkedRHandler = () => {
       let checkCon = checkedRef.current.disabled;
       let checkedVal = checkedRef.current.checked;
       let rnumTemp = [];
       let rresultTemp = [];
       let closeTemp = [];
       let openTemp = []
       if(!checkCon){
            if(checkedVal){
                setLabelActive({background: "#FFF",color: "#00cfff"})
                setStyling({width: 0, opacity: 0, padding: 0});
                dispatch(actionAmountStatus(false));
                // R Number test
                if(fastChoosebol){
                    dispatch(actionStatus(false));
                    if(towdLists.length > 0){
                        if(towdRLists.length > 0){
                            towdRLists.map(rnumber => {
                                dispatch(remove(rnumber));
                            });
                        };  
                    };
                    if(towdRLists.length > 0){
                        dispatch(cleanRValue());
                    };
                };
            }else{
                setLabelActive({background: "#00cfff",color: "#FFF"});
                setStyling({width: "100%", opacity: 1, padding: "10px"});
                dispatch(actionAmountStatus(true));
                dispatch(actionStatus(true));
                if(fastChoosebol){
                    if(towdLists.length > 0){
                        towdLists.map(i => {
                            let result = numberBtn.filter(x => x.number[0] == i[1] && x.number[1] == i[0]);
                            result.map(num => {
                                if(!towdLists.includes(num.number)){
                                    rnumTemp.push(num.number);
                                };
                            });
                        });
                    };
                    
                }; 
                rnumTemp.map(num => {
                    numberBtn.map(n => {
                        if(n.number == num){
                            rresultTemp.push(n);
                        }
                    });
                });
                rresultTemp.map(value => {
                    if(value.off || value.percentage == 100){
                        closeTemp.push(value);
                    }else{
                        openTemp.push(value);
                    }
                });
                if(closeTemp.length > 0){
                    closeTemp = closeTemp.map(cl => cl.number);
                    alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
                }
                rnumTemp = openTemp.map(value => value.number);
                if(rnumTemp.length > 0){
                    rnumTemp.map(num => {
                        dispatch(addR(num));
                        dispatch(addNormal(num));
                    })
                };
                
            };
       };
   };


   const inputRHandler = (e) =>{
        setRamount(e.target.value);
        dispatch(ramoutAction(e.target.value));
   };
   
   const numberValue = e => {
     setShowModal(false);
     let temp = [];
     let closeTemp = [];
     let openTemp = []
     switch(e.target.value){
        case "စုံစုံ":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[0] % 2 == 0 && el.number[1] % 2 == 0);
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "မမ":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[0] % 2 != 0 && el.number[1] % 2 != 0);  
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "စုံမ":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[0] % 2 == 0 && el.number[1] % 2 != 0);  
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "မစုံ":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[0] % 2 != 0 && el.number[1] % 2 == 0);  
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));
        
        case "ထိပ်0":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[0] == 0);
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "ထိပ်1":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[0] == 1); 
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "ထိပ်2":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[0] == 2); 
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));
        
        case "ထိပ်3":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[0] == 3); 
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "ထိပ်4":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[0] == 4); 
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));
        
        case "ထိပ်5":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[0] == 5); 
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "ထိပ်6":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[0] == 6); 
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "ထိပ်7":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[0] == 7); 
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));
        
        case "ထိပ်8":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[0] == 8); 
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "ထိပ်9":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[0] == 9); 
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));


        case "နောက်0":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[1] == 0); 
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "နောက်1":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[1] == 1); 
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "နောက်2":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[1] == 2); 
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));
        
        case "နောက်3":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[1] == 3); 
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "နောက်4":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[1] == 4); 
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));
        
        case "နောက်5":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[1] == 5); 
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "နောက်6":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[1] == 6); 
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "နောက်7":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[1] == 7); 
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));
        
        case "နောက်8":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[1] == 8); 
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "နောက်9":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[1] == 9); 
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "ပါတ်0":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[0] == 0 || el.number[1] == 0);
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "ပါတ်1":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[0] == 1 || el.number[1] == 1);
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "ပါတ်2":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[0] == 2 || el.number[1] == 2);
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "ပါတ်3":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[0] == 3 || el.number[1] == 3);
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "ပါတ်4":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[0] == 4 || el.number[1] == 4);
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "ပါတ်5":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[0] == 5 || el.number[1] == 5);
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "ပါတ်6":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[0] == 6 || el.number[1] == 6);
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "ပါတ်7":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[0] == 7 || el.number[1] == 7);
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "ပါတ်8":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[0] == 8 || el.number[1] == 8);
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "ပါတ်9":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[0] == 9 || el.number[1] == 9);
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "အပူး":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[0] == el.number[1]);
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "ညီအကို":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => el.number[0] && el.number[1] ==  Number(el.number[0]) + 1);
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "ဘရိတ်0":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => Number(el.number[0]) + Number(el.number[1]) ==  0 || Number(el.number[0]) + Number(el.number[1]) ==  10 );
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "ဘရိတ်1":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => Number(el.number[0]) + Number(el.number[1]) ==  1 || Number(el.number[0]) + Number(el.number[1]) ==  11 );
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "ဘရိတ်2":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => Number(el.number[0]) + Number(el.number[1]) ==  2 || Number(el.number[0]) + Number(el.number[1]) ==  12 );
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "ဘရိတ်3":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => Number(el.number[0]) + Number(el.number[1]) ==  3 || Number(el.number[0]) + Number(el.number[1]) ==  13 );
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "ဘရိတ်4":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => Number(el.number[0]) + Number(el.number[1]) ==  4 || Number(el.number[0]) + Number(el.number[1]) ==  14 );
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "ဘရိတ်5":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => Number(el.number[0]) + Number(el.number[1]) ==  5 || Number(el.number[0]) + Number(el.number[1]) ==  15 );
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "ဘရိတ်6":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => Number(el.number[0]) + Number(el.number[1]) ==  6 || Number(el.number[0]) + Number(el.number[1]) ==  16 );
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "ဘရိတ်7":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => Number(el.number[0]) + Number(el.number[1]) ==  7 || Number(el.number[0]) + Number(el.number[1]) ==  17 );
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "ဘရိတ်8":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => Number(el.number[0]) + Number(el.number[1]) ==  8 || Number(el.number[0]) + Number(el.number[1]) ==  18 );
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

        case "ဘရိတ်9":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            temp = numberBtn.filter(el => Number(el.number[0]) + Number(el.number[1]) ==  9 || Number(el.number[0]) + Number(el.number[1]) ==  19 );
            temp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));

            
        case "နက္ခတ်":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            let nat = ["07","18","24","35","42","53","69","70","81","96"];
            let natTemp = [];
            nat.map(num => {
                numberBtn.map(n => {
                    if(n.number == num){
                        natTemp.push(n);
                    }
                });
            });
            natTemp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));
        
        case "ပါဝါ":
            dispatch(actionStatus(true))
            dispatch(changeFastChooseValue(false));
            dispatch(changeFastChooseValue(false));
            let pow = ["05","16","27","38","49","50","61","72","83","94"];
            let powTemp = [];

            pow.map(num => {
                numberBtn.map(n => {
                    if(n.number == num){
                        powTemp.push(n);
                    }
                });
            });
            powTemp.map(value => {
                if(value.off || value.percentage == 100){
                    closeTemp.push(value);
                }else{
                    openTemp.push(value);
                }
            });
            if(closeTemp.length > 0){
                closeTemp = closeTemp.map(cl => cl.number);
                alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
            }
            temp = openTemp.map(value => value.number);
            dispatch(cleanValue());
            return dispatch(addNormal(temp));
            
        default:
            return;
     };
    };

    const chunk = num => {
        let str = num.toString().split('.');
        if (str[0].length >= 4) {
            str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        }
        if (str[1] && str[1].length >= 5) {
            str[1] = str[1].replace(/(\d{3})/g, '$1 ');
        }
        return str.join('.');
    };

  return (
    <>
       <FastChooseDiv>
            <div>
                <button onClick={modalHandler}>အမြန်ရွေး</button>
                <Link to="parmu">
                     <ParmuSpan>
                        ခွေထိုးမယ်
                     </ParmuSpan>
                </Link>
            </div>
            <div>
              <img src = {walletImg} alt = "wallet" style={{width: "23px", marginRight: "7px"}} />
              <span>{wallet ? chunk(String(wallet)) : 0}</span>
            </div>
       </FastChooseDiv>
       <RecordNav 
           id = {id}
           bgColor = "#01576a" 
           voucher = "/twod/voucher" 
           history = "/twod/history"
           lotteryWinner = "/twod/lottery-winner"
           duringThelottery = "/twod/during-the-lottery" />
       <TotalDiv>
           <input type="number" disabled={amountActionBtn} placeholder={`Min amount ${min}`} id='val' ref = {amountRef} onChange={lengthHandler}/>
           <div>
                <input type="checkbox" disabled={toggleR}  id = "showR" ref={checkedRef} />
                <label htmlFor="showR"  style={{backgroundColor: labelActive.background, color: labelActive.color}} onClick={checkedRHandler}>
                    R
                </label>   
           </div>
           <motion.input type="number" value = {ramout} onChange={inputRHandler} placeholder='Amount' 
           initial = {{width: styling.width,opacity: styling.opacity, padding: styling.padding}}
           animate = {{
             transition: {
                duration: 0.4
             },
             width: styling.width,
             opacity: styling.opacity,
             padding: styling.padding
           }}
           ></motion.input>
       </TotalDiv>
       {
         createPortal( showModal && 
            <ModalBox close = {setShowModal} title = "အမြန်ရွေး">
                 <ul>
                     <li>
                        <FastChooseH4>နက္ခတ်၊ ပါဝါ</FastChooseH4>
                        <div className='row'>
                            <div className='col-3 p-1'>
                                <SelectBtn value="နက္ခတ်" onClick={(e) => numberValue(e)}>နက္ခတ်</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="ပါဝါ" onClick={(e) => numberValue(e)}>ပါဝါ</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="အပူး" onClick={(e) => numberValue(e)}>အပူး</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="ညီအကို" onClick={(e) => numberValue(e)}>ညီအကို</SelectBtn>
                            </div>   
                        </div>
                    </li>
                    <LineThrough />
                    <li>
                        <FastChooseH4>ရိုးရိုး</FastChooseH4>
                        <div className='row'>
                            <div className='col-3 p-1'>
                                <SelectBtn value="စုံစုံ" onClick={(e) => numberValue(e)}>စုံစုံ</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="စုံမ" onClick={(e) => numberValue(e)}>စုံမ</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="မစုံ" onClick={(e) => numberValue(e)}>မစုံ</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="မမ" onClick={(e) => numberValue(e)}>မမ</SelectBtn>
                            </div>   
                        </div>
                    </li>
                    <LineThrough />
                    <li>
                        <FastChooseH4>ပါတ်</FastChooseH4>
                        <div className='row'>
                            <div className='col-3 p-1'>
                                <SelectBtn value="ပါတ်0" onClick={(e) => numberValue(e)}>0</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="ပါတ်1" onClick={(e) => numberValue(e)}>1</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="ပါတ်2" onClick={(e) => numberValue(e)}>2</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="ပါတ်3" onClick={(e) => numberValue(e)}>3</SelectBtn>
                            </div>  
                            <div className='col-3 p-1'>
                                <SelectBtn value="ပါတ်4" onClick={(e) => numberValue(e)}>4</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="ပါတ်5" onClick={(e) => numberValue(e)}>5</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="ပါတ်6" onClick={(e) => numberValue(e)}>6</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="ပါတ်7" onClick={(e) => numberValue(e)}>7</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="ပါတ်8" onClick={(e) => numberValue(e)}>8</SelectBtn>
                            </div> 
                            <div className='col-3 p-1'>
                                <SelectBtn value="ပါတ်9" onClick={(e) => numberValue(e)}>9</SelectBtn>
                            </div>
                        </div>
                    </li>
                    <LineThrough />
                    <li>
                        <FastChooseH4>ထိပ်</FastChooseH4>
                        <div className='row'>
                            <div className='col-3 p-1'>
                                <SelectBtn value="ထိပ်0" onClick={(e) => numberValue(e)}>0</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="ထိပ်1" onClick={(e) => numberValue(e)}>1</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="ထိပ်2" onClick={(e) => numberValue(e)}>2</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="ထိပ်3" onClick={(e) => numberValue(e)}>3</SelectBtn>
                            </div>  
                            <div className='col-3 p-1'>
                                <SelectBtn value="ထိပ်4" onClick={(e) => numberValue(e)}>4</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="ထိပ်5" onClick={(e) => numberValue(e)}>5</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="ထိပ်6" onClick={(e) => numberValue(e)}>6</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="ထိပ်7" onClick={(e) => numberValue(e)}>7</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="ထိပ်8" onClick={(e) => numberValue(e)}>8</SelectBtn>
                            </div> 
                            <div className='col-3 p-1'>
                                <SelectBtn value="ထိပ်9" onClick={(e) => numberValue(e)}>9</SelectBtn>
                            </div>
                        </div>
                    </li>
                    <LineThrough />
                    <li>
                        <FastChooseH4>နောက်</FastChooseH4>
                        <div className='row'>
                            <div className='col-3 p-1'>
                                <SelectBtn value="နောက်0" onClick={(e) => numberValue(e)}>0</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="နောက်1" onClick={(e) => numberValue(e)}>1</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="နောက်2" onClick={(e) => numberValue(e)}>2</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="နောက်3" onClick={(e) => numberValue(e)}>3</SelectBtn>
                            </div>  
                            <div className='col-3 p-1'>
                                <SelectBtn value="နောက်4" onClick={(e) => numberValue(e)}>4</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="နောက်5" onClick={(e) => numberValue(e)}>5</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="နောက်6" onClick={(e) => numberValue(e)}>6</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="နောက်7" onClick={(e) => numberValue(e)}>7</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="နောက်8" onClick={(e) => numberValue(e)}>8</SelectBtn>
                            </div> 
                            <div className='col-3 p-1'>
                                <SelectBtn value="နောက်9" onClick={(e) => numberValue(e)}>9</SelectBtn>
                            </div>
                        </div>
                    </li>
                    <LineThrough />
                    <li>
                        <FastChooseH4>ဘရိတ်</FastChooseH4>
                        <div className='row'>
                            <div className='col-3 p-1'>
                                <SelectBtn value="ဘရိတ်0" onClick={(e) => numberValue(e)}>0</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="ဘရိတ်1" onClick={(e) => numberValue(e)}>1</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="ဘရိတ်2" onClick={(e) => numberValue(e)}>2</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="ဘရိတ်3" onClick={(e) => numberValue(e)}>3</SelectBtn>
                            </div>  
                            <div className='col-3 p-1'>
                                <SelectBtn value="ဘရိတ်4" onClick={(e) => numberValue(e)}>4</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="ဘရိတ်5" onClick={(e) => numberValue(e)}>5</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="ဘရိတ်6" onClick={(e) => numberValue(e)}>6</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="ဘရိတ်7" onClick={(e) => numberValue(e)}>7</SelectBtn>
                            </div>
                            <div className='col-3 p-1'>
                                <SelectBtn value="ဘရိတ်8" onClick={(e) => numberValue(e)}>8</SelectBtn>
                            </div> 
                            <div className='col-3 p-1'>
                                <SelectBtn value="ဘရိတ်9" onClick={(e) => numberValue(e)}>9</SelectBtn>
                            </div>
                        </div>
                    </li>
                 </ul>
            </ModalBox>, document.getElementById("portal"))
       }
       {
        createPortal( loading && <Loading />, document.getElementById("portal"))
       }
    </>
  )
}

export default TwodFastChoose