import React, { useEffect, useRef, useState } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import styled from 'styled-components';
import { add , remove } from '../store/threedNumberList';
import { FaLock } from "react-icons/fa";

const NumberButton = styled.label`
    width: 76px;
    height: 76px;
    border: none;
    background-color: #FFF;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3px;
    border-radius: 10px;
    font-size: 21px;
    letter-spacing: 2px;
    font-weight: bold;
    position: relative;
    cursor: pointer;
    user-select: none;

    &focus:{
        background-color: ${props => props.theme.secondary}
    }
    span {
      height: 6px;
      display: block;
      position: absolute;
      bottom: 5px;
      left: 5px;
      right: 5px;
      border-radius: 10px;
      background-color: #808080;
    }

    @media (min-width: 461px) and (max-width: 479px) {
      width: 75px;
      height: 75px;
    }
    @media (min-width: 441px) and (max-width: 460px) {
      width: 72px;
      height: 72px;
    }

    @media (min-width: 421px) and (max-width: 440px) {
      width: 68px;
      height: 68px;
    }

    @media (min-width: 401px) and (max-width: 420px) {
      width: 65px;
      height: 65px;
      margin-bottom: 12px;
    }

    @media (min-width: 361px) and (max-width: 372px) {
      width: 57px;
      height: 57px;
    }

    @media (min-width: 373px) and (max-width: 400px) {
      width: 62px;
      height: 62px;
    }

    @media (min-width: 351px) and (max-width: 360px) {
      width: 55px;
      height: 55px;
    }

    @media (min-width: 340px) and (max-width: 350px) {
      width: 52px;
      height: 52px;
    }
`

const LockDiv = styled.div`
     width: 100%;
     height: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
     color: #FFF;
     background-color: #00000085;
     position: absolute;
     cursor: pointer;
     user-select: none;
     border-radius: 10px;
     z-index: 10;
`


const ThreedNumberBtn = ({number,status,percentage}) => {
  const [btnActive, setBtnActive] = useState({background: "#FFF",color: "#000"});
  const dispatch = useDispatch();
  const checkedRef = useRef();
  const [color,setColor] = useState("");
  let barStatus = Math.trunc(percentage);

  useEffect(()=>{
    if(barStatus > 1 && barStatus < 51){
      setColor("green")
    }else if(barStatus > 51 && barStatus < 76){
      setColor("yellow")
    }else if(barStatus > 76 && barStatus <= 100){
      setColor("red")
    }
  },[percentage])

  const numberValueHandler = () => {
    if(!checkedRef.current.disabled){
      if(!checkedRef.current.checked){
          setBtnActive({background: "#00cfff",color: "#FFF"});
          dispatch(add(number));
      }else{
          setBtnActive({background: "#FFF",color: "#000"}); 
          dispatch(remove(number));
      };
    };
        
  };
  return (
    <div>
      <div>
      <input type="checkbox" disabled={status || barStatus == 100 ? true: false} id={number} value={number} ref = {checkedRef} style={{display:"none"}} />
      <NumberButton htmlFor={number} style={{backgroundColor: btnActive.background, color: btnActive.color}} onClick={numberValueHandler}>
          {number}
          <span>
              <div 
               style={{
                   backgroundColor: color,
                   width: `${barStatus}%`,
                   height: "100%",
                   borderRadius: '10px',
                }}
              ></div>
          </span>
          {
            status && <LockDiv>
                          <FaLock size="20px" />
                      </LockDiv>
          }
      </NumberButton>
    </div>
    </div>
  )
}

export default ThreedNumberBtn;
