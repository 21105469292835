import React from 'react';
import { motion } from "framer-motion";
import {FiX} from "react-icons/fi";
import styled from 'styled-components';

const ModalDiv = styled.div`
   position: fixed;
   z-index: 9999;
   background-color: rgba(7,14,7,.8);
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   display: flex;
   justify-content: center;
   align-items: center;
  
   .modalCard {
     width: 440px;
     background-color: #FFF;
     border-radius: 20px;
     padding: 20px 30px 30px 30px;
     height: 100%;
     overflow: hidden auto;
   }
`
const ModalTitle = styled.p`
   color: #444;
   text-align: center;
   margin-bottom: 30px;
   position: relative;

   button {
      border: none;
      font-size: 25px;
      background-color: transparent;
      cursor: pointer;
      position: absolute;
      right: -10px;
      bottom: 0;
      color: #444;
   }
`
const ModalBox = ({children,close,title}) => {
  return (
    <ModalDiv onClick={() => close(false)}>
         <motion.div className='modalCard' onClick={e => e.stopPropagation()}
           initial = {{opacity: 0,y: -50}}
           animate = {{
             opacity: 1,
             transition: {
                duration: 0.5
             },
             y: 0
           }}
          >
            <ModalTitle>{title} <button onClick={() => close(false)}><FiX /></button></ModalTitle>
            {
               children
            }
         </motion.div>
    </ModalDiv>
  )
}

export default ModalBox