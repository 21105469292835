import React, { useEffect, useState } from 'react'
import HeaderNav from './HeaderNav'
import styled from 'styled-components'
import { getMethodToken } from '../Apis/Apis'
import { useParams,useNavigate, Link } from 'react-router-dom'
import EmptyRecord from '../utils/EmptyRecord';
import { createPortal } from 'react-dom'
import Loading from '../utils/Loading';
import {FiChevronRight} from 'react-icons/fi';

const TimeH1 = styled.h1`
    margin-bottom: 0;
    font-size: 20px;
    text-align: center;
`
const DayContainerDiv = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
    button {
        padding: 7px 10px;
        border: none;
        font-size: 15px;
        cursor: pointer;
        border-radius: 5px;
        &:focus {
            box-shadow: 0 -2px 5px rgb(0 0 0 / 54%);
          }
    }
`

const HistoryTitle = styled.h5`
    text-align: center;
    color: #FFF;
    font-size: 15px;
    margin-bottom: 0;
`
const ListVoucher = styled.li`
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 13px;
    &:last-child {
        margin-bottom: 0;
    }
`
const CardDiv = styled.div`
   padding: 18px;
   background-color: #FFF;
   border-radius: 10px;
   display:flex;
   align-items: flex-start;
   justify-content: space-between;
   color: #090b42f2;

   span {
    display: block;
   }
`

const Ul = styled.ul`
   height: 60vh;
   overflow: hidden auto;
`

const History = ({type}) => {
  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  const [day,setDay] = useState(currentDate.toISOString().replace(/T.*/,'').split('-').join('-'));
  const [voucher, setVoucher] = useState([]);
  const [timeRes,setTimeRes] = useState("--:--");
  const [loading,setLoading] = useState(true);
  const [active, setActive] = useState(currentDate.toISOString().replace(/T.*/,'').split('-').join('-'));
  const navigate = useNavigate();
  const [length, setLength] = useState(true);
  
  let id = useParams();
  let lastThree = [1,2,3];
  let lastDays = [];
  lastThree.map(day => {
        let date = new Date();
        date.setDate(date.getDate() - day);
        let resDate = date.toISOString().replace(/T.*/,'').split('-').join('-');
        if(!lastDays.includes(resDate)){
           lastDays.push(resDate);
        };
  });
 
  useEffect(()=> {
        let token = localStorage.getItem("token");
            if(token){
                const fetchData = async () => {
                    const response = await getMethodToken(`/rounds`,token);
                    if(response){
                        if(response.message === "Unauthenticated."){
                            localStorage.removeItem("token");
                            navigate('/login');
                            console.clear();
                          }else{
                            if(response.data){
                                response.data.map(async el => {
                                    if(el.id == id.id){
                                        setTimeRes(timeConvert(el.name))
                                        const isEmpty = Object.keys(el).length !== 0;
                                        if(isEmpty){
                                            let responseRound = await getMethodToken(`/bets?date=${day}&round_id=${id.id}`, token);
                                            if(responseRound.data){
                                                setVoucher(responseRound.data);
                                                setLoading(false);
                                                if(responseRound.data.length > 0){
                                                  setLength(true);
                                                }else{
                                                  setLength(false);
                                                }
                                            };
                                        }
                                    }
                                });
                            }
                          };
                    };
                };
                fetchData();
        };
  },[day]);
  function timeConvert (time) {
        time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        if (time.length > 1) { 
        time = time.slice (1); 
        time[5] = +time[0] < 12 ? ' AM ' : ' PM ';
        time[0] = +time[0] % 12 || 12;
        };
        time.splice(3,1);
        return time.join ('');
  };

  const timeBtnHandler = (e) => {
      setLoading(true);
      setDay(e.target.innerText);
      setActive(e.target.innerText);
      if(e.target.innerText === day){
          setLoading(false);
      };
  };
  return (
    <>
       <HeaderNav type = {type} />
       <div style={{padding: "20px 20px 0 20px",overflowX: "auto", position: "relative"}}>
            <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '20px'
                    }}>
                <TimeH1>{timeRes}</TimeH1>
                <HistoryTitle>{type} ထီထိုးမှတ်တမ်း</HistoryTitle>
            </div>
            {
                length > 0 ?
                <Ul>
                    {voucher.map((vou,index) => 
                        <ListVoucher key={index}>
                            <Link to={`detail/${vou.id}`}>
                                <CardDiv>
                                    <div>
                                        <span style={{marginBottom: "10px"}}>ပွဲစဉ်</span>
                                        <div>
                                        <span>{vou.date}</span>
                                        <span>{timeRes}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <span style={{marginBottom: "10px"}}>ထီထိုးချိန်</span>
                                        <div>
                                        <span>{vou.date}</span>
                                        <span>{vou.time}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <span style={{marginBottom: "10px"}}>အရေတွက်</span>
                                        <div>
                                        <span style={{textAlign: "center"}}>{vou.bet_count}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <span style={{marginBottom: "10px"}}>ငွေပမာဏ</span>
                                        <div>
                                        <span style={{textAlign: "center"}}>{vou.total_bet_amount}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <span style={{marginBottom: "10px"}}>ကော်မရှင်</span>
                                        <div>
                                        <span style={{textAlign: "center"}}>{vou.commission}</span>
                                        </div>
                                    </div>
                                </CardDiv>
                            </Link>
                        </ListVoucher>
                    )}
                </Ul>:
            <EmptyRecord message = "မှတ်တမ်းမရှိပါ"/>
            }   
       </div>
       <DayContainerDiv>
               {
                 lastDays.length > 0 &&
                 lastDays.map((day,index) => <button key={index} onClick = {timeBtnHandler} type="button" style={{backgroundColor: active === day && "#01576a", color: active === day && "#FFF"}}>{day}</button>)
               }
       </DayContainerDiv>
       {
        createPortal( loading && <Loading />, document.getElementById("portal"))
       }
    </>
  )
}

export default History
