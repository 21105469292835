import React,{useEffect, useState} from 'react'
import {useNavigate, useParams } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components';
import moment from 'moment-timezone';
import CloseTimeHeader from './CloseTimeHeader';
import HeaderNav from './HeaderNav';
import ThreedChooseNumber from './ThreedChooseNumber';
import {addSubmitValue, cleanSubmitValue} from '../store/submitNumberSlice';
import RecordNav from './RecordNav';
import { createPortal } from 'react-dom';
import Loading from '../utils/Loading';
import { getMethodToken } from '../Apis/Apis';

const HtoeBtn = styled.button`
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%,0);
    border: none;
    padding: 10px 35px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 10px;
    color: #4d2707;
    z-index: 9999;
    background-image: -webkit-linear-gradient(92deg,#fdd164,#daa520);
    &:focus {
        box-shadow: 0 0 0 2px #fbbf18;
    }
`
const Threed = () => {
    const usrId = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading,setLoading] = useState(true);
    const total = useSelector(state => state.totallength.value);
    const threednumberList = useSelector(state => state.threedList.value);
    const [min,setMin] = useState(0);
    const [max,setMax] = useState(0);
    let paramId = useParams();


    let data = {
        round_id: Number(usrId.id),
        bet_type: "Hundreds",
        time: moment().tz("Asia/Yangon").format("HH:mm:ss")
        // time: moment().tz("Asia/Yangon").format("YYYY-MM-DD HH:mm:ss")
    };
    
    useEffect(()=>{
        dispatch(cleanSubmitValue());
        dispatch(addSubmitValue(data));
    },[]);

    useEffect(()=>{
        let token = localStorage.getItem("token");
        if(token){
            const fetchData = async () => {
                const response = await getMethodToken(`/rounds?game_type=3D`,token);
                if(response){
                    if(response.message === "Unauthenticated."){
                        localStorage.removeItem("token");
                        navigate('/login');
                        console.clear();
                    }else{  
                        response.data.length > 0 && 
                        response.data.map(async el => {
                            if(el.id == paramId.id){
                                const result = await getMethodToken(`/settings?type=${el.type}`,token);
                                if(result){
                                    if(result.data){
                                        setMin(result.data[0].min_per_bet);
                                        setMax(result.data[0].max_per_bet);
                                    };
                                    setLoading(false);
                                };
                            };
                        });
                        setLoading(false);
                    };
                };
            };
            fetchData();
        }
    },[]);
    
    const totalHandler = () => {
        if(Number(total) < min){
            return alert(`Minium amount is ${min}`);
        };
        if(Number(total) > max){
            return alert(`Maxium amount is ${max}`);
        };
        if(Number(total) % min !== 0){
            return;
        }
        if(threednumberList.length > 0){
            navigate("total");
        };
    };
   
    return (
      <>
         <HeaderNav type = "3D" />
         <CloseTimeHeader setLoading = {setLoading} />
         <RecordNav 
            id={usrId.id}
            voucher = "/threed/voucher" 
            history = "/threed/history"
            lotteryWinner = "/threed/lottery-winner"
            duringThelottery = "/threed/during-the-lottery"
          />
         <ThreedChooseNumber />
         <HtoeBtn onClick={totalHandler}>ထီထိုးမည်</HtoeBtn>
         {
            createPortal( loading && <Loading />, document.getElementById("portal"))
         }
      </>
    )
}

export default Threed
