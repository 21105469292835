import {createSlice} from "@reduxjs/toolkit";

const activeRdisableAll = createSlice({
     name: "Raction",
     initialState: {
        value: false
     },
     reducers: {
        actionStatus: (state,action) => {
            state.value = action.payload;
        }
     }
});

export const {actionStatus} = activeRdisableAll.actions;
export default activeRdisableAll.reducer;