import React, { useEffect, useState } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import styled from 'styled-components';
import { getMethodToken } from '../Apis/Apis';
import EmptyRecord from '../utils/EmptyRecord';
import '../styles/TransitionStatusTabs.css';

const TabsContainer = styled.div`
    padding: 20px;
`
const Table = styled.table`
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 200%;

  td, th {
    border: 1px solid #ddd;
    padding: 8px;
    color: white;
    text-align: center;
    font-size: 13px;
  }

  tr:hover {background-color: #00000036;cursor: pointer;}

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: rgb(1, 87, 106);
  }
`

const Tbody = styled.tbody`
    // display: block;
    // max-height: 45vh;
    // overflow: hidden auto;
`

const TitleDiv = styled.div`
  text-align: center;
  padding-bottom: 5px;
`;

const WidthdrawHistoryTable = ({setLoading}) => {
  const [transHistory, setTransHistory] = useState([]);
  const [outHistory, setOutHistory] = useState([]);
  const [length, setLength] = useState(true);
  const navigate = useNavigate();

  useEffect(()=>{

      let token = localStorage.getItem("token");
      if(token){
        const fetchData = async () => {
          let result = await getMethodToken("/widthdraw-histories", token)
          if(result){
            if(result.message === "Unauthenticated."){
              localStorage.removeItem("token");
              navigate("/login")
            }else{
              console.log('error or not')
              console.log(result.ok);
              if(result.data){
                setTransHistory(result.data);
                setLoading(false);
                if(result.data.length > 0){
                  setLength(true);
                }else{
                  setLength(false);
                }
              }else{
                setLoading(false);
                setLength(false);

              }
            };
          };
        };
        fetchData();
      }
  },[]);

  const chunk = num => {
      let str = num.toString().split('.');
      if (str[0].length >= 4) {
          str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
      }
      if (str[1] && str[1].length >= 5) {
          str[1] = str[1].replace(/(\d{3})/g, '$1 ');
      }
      return str.join('.');
  };
  
  return (
    <TabsContainer>
              {
                length > 0 ? 
                <div style={{overflowX:'auto'}}>
                  <Table>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Bank</th>
                        <th>Account</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <Tbody>
                        {
                          transHistory.map((fill,index) => 
                          <tr key={index}>
                            <td>{index + 1}.</td>
                            <td>{fill.bank}</td>
                            <td>{fill.account}</td>
                            <td>{fill.amount}</td>
                            <td>{fill.status}</td>
                            <td>{fill.created_at}</td>
                          </tr>
                          )
                        }
                    </Tbody>
                  </Table>
                </div> :
                <EmptyRecord message="ငွေဖြည့်မှတ်တမ်းမရှိပါ" />
              }
    </TabsContainer>
  )
}

export default WidthdrawHistoryTable
