import React,{useState,useEffect} from 'react'
import styled from 'styled-components';
import HeaderNav from './HeaderNav';
import SubmitNumber from './SubmitNumber';
import walletImg from '../images/wallet.svg';
import { getMethodToken } from '../Apis/Apis';
import ParmuNumbersContainer from './ParmuNumbersContainer';
import ParmuNumberForm from './ParmuNumberForm';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {cleanValue} from '../store/towdNumbersList'

const NumberListContainer = styled.div`
   position: sticky;
   top: 0;
   z-index: 999;
`
const FastChooseDiv = styled.div`
        height: 65px;
        background-color: ${props => props.theme.secondary};
        padding: 0 20px 0px 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        div {
            display: flex;
            align-items: center;
        }

        img {
        width: 25px;
        margin-right: 5px;
        }
        span {
            color: #FFF;
            font-size: 17px;
        }
`

const ParmuNumber = () => {
    const [wallet,setWallet] = useState("0");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect( () => {
        dispatch(cleanValue());
        let token = localStorage.getItem("token");
        if(token){
            const fetchData = async () => {
                const result = await getMethodToken("/user",token);
                if(result){
                    if(result.message === "Unauthenticated."){
                        localStorage.removeItem("token");
                        navigate('/login');
                        console.clear();
                    }else{
                        setWallet(result.wallet.balance);
                    };
                };
            };
            fetchData();
        };
    },[]);

    const chunk = num => {
        let str = num.toString().split('.');
        if (str[0].length >= 4) {
            str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        }
        if (str[1] && str[1].length >= 5) {
            str[1] = str[1].replace(/(\d{3})/g, '$1 ');
        }
        return str.join('.');
    };

  return (
    <>
        <NumberListContainer>
            <HeaderNav />
            <FastChooseDiv>
                <div>
                    <img src = {walletImg} alt = "wallet" style={{width: "23px", marginRight: "7px"}} />
                    <span>လက်ကျန်ငွေ - {chunk(String(wallet))}</span>
                </div>
            </FastChooseDiv>
            <ParmuNumberForm />
        </NumberListContainer>
        <ParmuNumbersContainer/>

        <SubmitNumber />
    </>
  )
}

export default ParmuNumber