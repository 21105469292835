import { createSlice } from "@reduxjs/toolkit";

const threedNumberList = createSlice({
    name: "theed number",
    initialState: {
        value: [],
    },
    reducers: {
        add: (state,action) => {
            state.value = [action.payload,...state.value];
        },
        remove: (state,action) => {
            state.value = state.value.filter(id => action.payload !== id);
        },
        cleanthreedLists: (state,action) => {
            state.value = [];
        }
    }
});

export const {add,remove,cleanthreedLists} = threedNumberList.actions;
export default threedNumberList.reducer;