import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components'
import { getMethodToken } from '../Apis/Apis';
import { totalAmount } from '../store/amountSlice';
import ThreedNumberWheel from './ThreedNumberWheel'

const NumbersContaienr = styled.div`
    padding: 0 20px
`
const AmountInput = styled.input`
    width: 100%;
    outline: none;
    border-radius: 10px;
    border: none;
    height: 100%;
    border-color: #c0c4cc;
    font-size: 18px;
    text-align: right;
    color: #606266;
    padding: 10px;
`

const ThreedChooseNumber = () => {
  const [min,setMin] = useState(0);
  const [max,setMax] = useState(0);
  const dispatch = useDispatch();
  let id = useParams();
  const navigate = useNavigate();
  const amountHandler = e => {
    dispatch(totalAmount(e.target.value));
  };

  useEffect(()=>{
    let token = localStorage.getItem("token");
    if(token){
        const fetchData = async () => {
            const response = await getMethodToken(`/rounds/${id.id}`,token);
            if(response){
              if(response.message === "Unauthenticated."){
                localStorage.removeItem("token");
                navigate('/login');
                console.clear();
              }else{
                  const result = await getMethodToken(`/settings?type=${response.data.type}`,token);
    
                  if(result){
                    if(result.data){
                      setMin(result.data[0].min_per_bet);
                      setMax(result.data[0].max_per_bet);
                    };
                  };
              };
            };
        };
        fetchData();
    };
  },[]);
  return (
    <NumbersContaienr>
       <AmountInput type="number" onChange={amountHandler} placeholder={`Min amount ${min}`} />
       <ThreedNumberWheel />
    </NumbersContaienr>
  )
}

export default ThreedChooseNumber
