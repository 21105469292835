import React from 'react';
import styled from 'styled-components';
import SingleApp from './SingleApp';
import twoDImg from '../images/2D.png';
import threeDImg from '../images/3D.png';
import football from '../images/football.png';
import slot from '../images/Slot.png';

const ParentDiv = styled.div`
   padding: 20px;
`

const ListsRow = () => {
  let yourDateObject = new Date();
  let dayOfWeek = yourDateObject.getDay();
  let isWeekend = (dayOfWeek === 6) || (dayOfWeek  === 0); // 6 = Saturday, 0 = Sunday

  return (
    <ParentDiv>
        <div className='row'>
           {/* <SingleApp name="2D" image = {twoDImg} alt = "2d image" path = {isWeekend? "/close":"/twod"} /> */}
           <SingleApp name="2D" image = {twoDImg} alt = "2d image" path = {"/twod"} />
           <SingleApp name="3D" image = {threeDImg} alt = "3d image" path = "/threed/time"   />
           <SingleApp name="SPORT" image = {football} alt = "football image" path = "#"  />
           <SingleApp name="Slot" image = {slot} alt = "slot image" path = "#"  />
        </div>
    </ParentDiv>
  )
}

export default ListsRow;