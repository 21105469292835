import React from 'react';
import {Link,useNavigate} from 'react-router-dom';
import styled from 'styled-components';


const ParentCard = styled.div`
     padding: 0 20px 20px 20px;
`

const ChildCard = styled.div`
    background-color: #FFF;
    padding: 10px;
    color: #000 !important;
    border-radius: 10px;
    color: ${props => props.theme.primary};
`
const Li = styled.li`
  color: #343434;
  padding: 8px 10px;
  text-decoration: none;
  display: block;
  transition: 0.5s;
  border-radius: 5px;
  outline: none;

  &:first-child {
    text-align: center;
  }
`

const LogoutBtn = styled.button`
    border: none;
    padding: 10px 30px;
    background-color: ${props => props.theme.primary};
    color: #f5f5f5;
    outline: none;
    border-radius: 5px;
`

const TuturialCard = () => {
  return (
    <ParentCard>
        <ChildCard>
             <ul>
                <Li>ငွေသွင်းနည်း</Li>
                <Li>
                    ၁။  'ငွေသွင်း' ခလုပ်နှိပ်ပါ။
                </Li>
                <Li>
                    ၂။ လွဲမည့်အကောင့်ကိုရွေးချယ်ပါ။
                </Li>
                <Li>
                    ၃။ မိမိငွေလွှဲထားသော ငွေပမာဏ နှင့် လုပ်ငန်စဉ်နံပါတ်နောက် ၆ လုံးထည့်ပါ။
                </Li>
                <Li>
                    ၄။ မိမိအကောင် password ထည့်ပြီး အတည်ပါ။
                </Li>
             </ul>
        </ChildCard>
    </ParentCard>
  )
}

export default TuturialCard
