import { createSlice } from "@reduxjs/toolkit";
const twodSetEveValueSlice = createSlice({
    name: "setValue",
    initialState: {
        value: {}
    },
    reducers: {
        addSetEveValue : (state,action) => {
            state.value = action.payload;
        },
        cleanSetEveValue: (state) => {
            state.value = {}
        }
    }
});

export const {addSetEveValue,cleanSetEveValue} = twodSetEveValueSlice.actions;
export default twodSetEveValueSlice.reducer;