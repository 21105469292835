import { createSlice } from "@reduxjs/toolkit";

const actionRbtn = createSlice({
     name: "R",
     initialState: {
        value: true
     },
     reducers: {
        toggleRaction: (state,action) => {
            state.value = action.payload;
        }
     }
});

export const {toggleRaction} = actionRbtn.actions;
export default actionRbtn.reducer;