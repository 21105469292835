import React, { useState,useEffect } from 'react'
import styled from 'styled-components';
import { getMethodToken } from '../Apis/Apis';
import HeaderNav from './HeaderNav';
import { useParams, useNavigate, Link } from 'react-router-dom';
import EmptyRecord from '../utils/EmptyRecord';
import { createPortal } from 'react-dom';
import Loading from '../utils/Loading';

const TimeH1 = styled.h1`
    margin-bottom: 0;
    font-size: 20px;
    text-align: center;
`
const HistoryTitle = styled.h5`
    text-align: center;
    color: #FFF;
    font-size: 15px;
    margin-bottom: 0;
`
const ListVoucher = styled.li`
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 13px;
    &:last-child {
        margin-bottom: 0;
    }
`
const CardDiv = styled.div`
   padding: 18px;
   background-color: #FFF;
   border-radius: 10px;
   display:flex;
   align-items: flex-start;
   justify-content: space-between;
   color: #090b42f2;

   span {
    display: block;
   }
`

const Voucher = ({type}) => {
  const [voucher, setVoucher] = useState([]);
  const [timeRes,setTimeRes] = useState("--:--");
  const [loading,setLoading] = useState(true);
  const [betTotal, setBetTotal] = useState(0);
  const [lottery, setLottery] = useState(0);
  const [length, setLength] = useState(true)
  const navigate = useNavigate();
  const id = useParams();
  
  useEffect(()=> {
        let token = localStorage.getItem("token");
            if(token){
                const fetchData = async () => {
                    const response = await getMethodToken(`/rounds`,token);
                    if(response){
                        if(response.message === "Unauthenticated."){
                            localStorage.removeItem("token");
                            navigate('/login');
                            console.clear();
                        }else{
                            response.data.map(async el => {
                                if(el.id == id.id){
                                    setTimeRes(timeConvert(el.name))
                                    const isEmpty = Object.keys(el).length !== 0;
                                    if(isEmpty){
                                        let responseRound = await getMethodToken(`/bets?start_time=${el.start_time}&end_time=${el.end_time}&type=${el.type}`, token)
                                        if(responseRound.data){
                                            setVoucher(responseRound.data);
                                            setLoading(false);
                                            let betTotal = responseRound.data.reduce((acc, obj) => acc + obj.amount, 0);
                                            setBetTotal(betTotal);
                                            if(responseRound.data.length > 0){
                                                setLength(true);
                                            }else{
                                                setLength(false);
                                            }
                                        };
                                    }
                                }
                            });
                        };
                    };
                };
                fetchData();
        };
  },[]);

  function timeConvert (time) {
        time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        if (time.length > 1) { 
        time = time.slice (1); 
        time[5] = +time[0] < 12 ? ' AM ' : ' PM ';
        time[0] = +time[0] % 12 || 12;
        };
        time.splice(3,1);
        return time.join ('');
  };

  const chunk = num => {
    let str = num.toString().split('.');
    if (str[0].length >= 4) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
        str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
 };
  return (
    <>
       <HeaderNav type = {type} />
       <div style={{padding: "20px 20px 0 20px",overflowX: "auto", position: "relative"}}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '20px'
            }}>
                <TimeH1>{timeRes}</TimeH1>
                <HistoryTitle>{type} ထီထိုးမှတ်တမ်း</HistoryTitle>
            </div>
            {
                length > 0 ?
                <ul>
                    {voucher.map((vou,index) => 
                        <ListVoucher key={index}>
                            <Link to={`detail/${vou.id}`}>
                                <CardDiv>
                                    <div>
                                        <span style={{marginBottom: "10px"}}>ပွဲစဉ်</span>
                                        <div>
                                        <span>{timeRes}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <span style={{marginBottom: "10px"}}>ထီထိုးချိန်</span>
                                        <div>
                                        <span>{vou.date}</span>
                                        <span>{vou.time}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <span style={{marginBottom: "10px"}}>အရေတွက်</span>
                                        <div>
                                        <span style={{textAlign: "center"}}>{vou.bet_count}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <span style={{marginBottom: "10px"}}>ငွေပမာဏ</span>
                                        <div>
                                        <span style={{textAlign: "center"}}>{vou.total_bet_amount ? chunk(String(vou.total_bet_amount)) : 0}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <span style={{marginBottom: "10px"}}>ကော်မရှင်</span>
                                        <div>
                                        <span style={{textAlign: "center"}}>{vou.commission ? chunk(String(vou.commission)) : 0}</span>
                                        </div>
                                    </div>
                                </CardDiv>
                            </Link>
                        </ListVoucher>
                    )}
                </ul>:
            <EmptyRecord message = "မှတ်တမ်းမရှိပါ"/>
            }   
       </div>
       {
            createPortal( loading && <Loading />, document.getElementById("portal"))
       }
    </>
  )
}

export default Voucher
