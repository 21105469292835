import React from 'react'
import styled from 'styled-components'

const Btn = styled.button`
   background-color: #42DF00;
   border-radius: 4px;
   border: none;
   padding: 17px;
   width: 100%;
   color: #FFF;
   font-size: 18px;
   letter-spacing: 1px
`

const Button = ({title,type}) => {
  return (
    <Btn type={type}>{title}</Btn>
  )
}

export default Button;