import React, { useEffect, useState } from "react";
import Header from "./Header";
import FooterNav from "./FooterNav";
import { createPortal } from "react-dom";
import Loading from "../utils/Loading";
import HeaderNav from "./HeaderNav";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Input from "../utils/Input";
import SubmitModal from '../utils/SubmitModal';
import { getMethodToken, postMethodRaw } from '../Apis/Apis';



const Container = styled.div`
  padding: 20px;
  color: #fff;
`;

const MyLabel = styled.div`
  padding: 10px 0 10px 0;
  font-size: 14px;
`;

const TitleDiv = styled.div`
  text-align: center;
  padding-bottom: 5px;
`;

const AuthBalanceDiv = styled.div`
  padding-top: 1rem;
  text-align: center;
`;

const BankCard = styled.div`
  background-color: #ffffff36;
  border-radius: 10px;
  overflow-x: auto;


  hr{
    margin: 0;
  }
`;
const Ul = styled.ul`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: ${(props) => props.getwidth > 100 ? props.getwidth - 15 :`100`}%;

  li {
    cursor: pointer;
    width: 25%;
  }
`;
const PhNoDiv = styled.div`
  padding: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  button{
    padding: 5px 10px 5px 10px;
    background:#fff;
    outline: none;
    border-radius: 5px;
    border: none;
  }
`;

const AccountDiv = styled.div`
  margin: 0;
`
const CopyBtnContainer = styled.div`
  button{
    padding: 5px 10px 5px 10px;
    background:#fff;
    outline: none;
    border-radius: 5px;
    border: none;
  }
`

const IconDiv = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => props.selected ? `#FFCC00` : `transparent`};
    padding: 3px;
  }

  span {
    font-size: 12px;
    margin-top: 6px;
  }
`;

const MyInput = styled.input`
    width: 100%;
    padding: 10px 40px 10px 10px;
    outline: none;
    border-radius: 5px;
    border: none;
    font-size: 14px;
`

const AmountBtnContainer = styled.div`
    padding: 10px 0 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const MyAmountBtn = styled.button`
    width: 30%;
    padding: 10px 40px 10px 40px;
    outline: none;
    border-radius: 5px;
    border: none;
    font-size: 14px;
`

const SubmitBtn = styled.button`
    width: 100%;
    padding: 10px 40px 10px 40px;
    outline: none;
    border-radius: 5px;
    border: none;
    font-size: 14px;
    margin-top: 10px;
`


const WalletTopupForm = () => {
  const [loading, setLoading] = useState(true);
  const [banks, setBanks] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [wallet, setWallet] = useState(0);
  const [amount, setAmount] = useState(null);
  const [password, setPassword] = useState(null);
  const [last6Digits, setLast6Digits] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [userNameError,setUserNameError] = useState("");
  const [userPassError,setUserPassError] = useState("");

  //model require state
  const [title,setTitle] = useState("");
  const [openBox,setOpenBox] = useState(false);
  const [timePath,setTimePath] = useState(false);
  const [message,setMessage] = useState("");
  const [fColor,setFcolor] = useState("");




  const navigate = useNavigate();

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      const fetchData = async () => {
        const result = await getMethodToken("/user", token);
        if (result) {
          if (result.message === "Unauthenticated.") {
            localStorage.removeItem("token");
            navigate("/login");
            console.clear();
          } else {
            setWallet(result.wallet.balance);
            // setLoading(false);
          }
        }

        const bank_response = await getMethodToken("/banks", token);
        if (bank_response) {
          if (bank_response.message === "Unauthenticated.") {
            // console.clear();
          } else {
            console.log(bank_response);
            setBanks(bank_response.data);
            setLoading(false);
          }
        }
      };
      fetchData();
    }
  }, []);

  const chunk = (num) => {
    let str = num.toString().split(".");
    if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, "$1 ");
    }
    return str.join(".");
  };

  const handleAmountChange = (e) =>{
    setAmount(e.target.value);
  }

  const handlePasswordChange = (e) =>{
    setPassword(e.target.value);
  }

  const handleLast6DigitChange = (e) =>{
    const digit = e.target.value.replace(/\D/g, '');
    const newValue = digit.slice(0, 6);
    setLast6Digits(newValue);
  }

  async function copyToClipboard() {
    await navigator.clipboard.writeText(selectedPayment.account_number);

    setShowTooltip(true);

    // Hide the tooltip after a short delay
    setTimeout(() => {
      setShowTooltip(false);
    }, 1000);
};

const handlePaymentSelect = (value) =>{
  // setSelectedPaymentAccount(value.account_number);
  setSelectedPayment(value);
}

const handleSubmit = async (e) =>{
  e.preventDefault();
  let obj = {
     'account' : selectedPayment.account_number,
     'bank' : selectedPayment.name,
     'amount' : amount,
     'last_six_digits' : last6Digits,
     'password' : password,
  };
  setLoading(true);
  console.log(obj);

  let token = localStorage.getItem("token");
  
  if(token){
    const response = await postMethodRaw('/topup',obj,token);
    if(response){
        if(response.errors){
            setTitle(response.message);
            setMessage(response.message);
            setFcolor("red");
            setLoading(false);
            setOpenBox(true);
            setTimePath(false);
        }else{
            setTitle("Success");
            setFcolor("green");
            setMessage("Success");
            setTimePath(true);
            obj = {};
            setLoading(false);
            // dispatch(cleanValue());
            // dispatch(cleanTotal());
            // dispatch(cleanRValue());
            // dispatch(actionAmountStatus(false));
            // dispatch(cleanSubmitValue());
            // dispatch(actionStatus(false));
            // dispatch(cleanNum());
            // dispatch(totalAmount(0));
            setOpenBox(true);
        };
    };
  };  
}
 
  return (
    <>
      <Container>
        <TitleDiv>
          <h6>ငွေသွင်း</h6>
        </TitleDiv>

        {/* select payment section */}
        <MyLabel>ငွေပေးချေမှုကိုရွေးချယ်ပါ</MyLabel>
        <BankCard>
          <Ul getwidth={((banks.length)*25)}>
            {
              banks.map(bank=>(
                <li onClick={() => handlePaymentSelect(bank)} key={bank.id}>
                  <IconDiv selected={selectedPayment === bank ? true : false}>
                    <img src="https://file.thai2d3dgame.com/files/payment/kbzpay.png" />
                    <span>{bank.name}</span>
                  </IconDiv>
                </li>
              ))
            }
          </Ul>
          {
            selectedPayment &&
            <>
              <hr/>
              <PhNoDiv>
                <AccountDiv>အကောင့်အမည်</AccountDiv>
                <CopyBtnContainer>
                  {selectedPayment.account_name}
                </CopyBtnContainer>
              </PhNoDiv>
              <PhNoDiv>
                <AccountDiv>{selectedPayment.account_number}</AccountDiv>
                <CopyBtnContainer>
                    <button onClick={()=>copyToClipboard()}>ကူယူမည်</button>
                </CopyBtnContainer>
              </PhNoDiv>
            </>
          }
        </BankCard>
        <AuthBalanceDiv>
          <p>လက်ကျန်ငွေ : {wallet ? chunk(String(wallet)) : 0}</p>
        </AuthBalanceDiv>
        <hr />

        <MyLabel>ငွေထည့်မည့်ပမာဏ</MyLabel>
        <MyInput type={'number'} placeholder="5000" value={amount} onChange={(e) => handleAmountChange(e)}/>
        
        <MyLabel>လုပ်ငန်စဉ်အမှတ် နောက်ဆုံး ၆ လုံး</MyLabel>
        <MyInput type={'text'} placeholder="1234456" value={last6Digits} onChange={(e)=> handleLast6DigitChange(e)}/>

        <MyLabel>စကားဝှက်</MyLabel>
        <MyInput type={'password'} placeholder="" value={password} onChange={(e)=> handlePasswordChange(e)}/>


      <SubmitBtn onClick={(e) => handleSubmit(e)}>အတည်ပြုမည်</SubmitBtn>
      
      </Container>
      {createPortal(loading && <Loading />, document.getElementById("portal"))}
      {
          createPortal( openBox && <SubmitModal title ={title} message = {message} close ={setOpenBox} timePathPoint = {timePath ? "/wallet/histories": null} color = {fColor} />, document.getElementById("portal"))
      }
    </>
  );
};

export default WalletTopupForm;
