import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import loginUser from "../images/profile.png";
import { getMethodToken } from '../Apis/Apis';


const UserDetailDiv = styled.div`
    display: flex;
    align-items: center;
    padding: 0 20px 20px 20px;
    
    img {
        width: 50px !important;
        height: 50px !important;
        object-fit: cover;
        border-radius: 50%;
    }
    h5 {
        background-color: transparent;
        margin-left: 10px;
        margin-bottom: 0;
        font-size: 15px;
        color: #fff;
    }
`

const LoginUserName = ({setLoading}) => {
  const [name,setName] = useState("");
  const navigate = useNavigate();
  useEffect( () => {
      let token = localStorage.getItem("token");
      if(token){
        const fetchData = async () => {
          const result = await getMethodToken("/user",token);
          if(result){
            if(result.message === "Unauthenticated."){
              localStorage.removeItem("token");
              navigate('/login');
              console.clear();
            }else{
              setName(result.name);
              setLoading(false);
            }
          };
        };
        fetchData();
      };
  },[]);
  
  return (
    <UserDetailDiv>
            <img src={loginUser} className="shadow" alt="User" />
            <h5>{name}</h5>
    </UserDetailDiv>
  )
}

export default LoginUserName;