import { createSlice } from "@reduxjs/toolkit";

const threedNumberSlice = createSlice({
    name: "threed numbers",
    initialState: {
        value: []
    },
    reducers: {
        addNums : (state,action) => {
            state.value = action.payload;
        },
        cleanNums : (state,action) => {
            state.value = [];
        }
    }
});

export const {addNums,cleanNums} = threedNumberSlice.actions;
export default threedNumberSlice.reducer;