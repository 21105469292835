import { createSlice } from "@reduxjs/toolkit";

const fastChooseAlertSlice = createSlice({
    name: "fastChooseAlert",
    initialState: {
        value: true,
    },
    reducers: {
        changeFastChooseValue: (state,action) => {
            state.value = action.payload;
        }
    }
});

export const {changeFastChooseValue} = fastChooseAlertSlice.actions;
export default fastChooseAlertSlice.reducer;