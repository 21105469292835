import { createSlice } from "@reduxjs/toolkit";

const amountSlice = createSlice({
    name: "amount",
    initialState: {
        value: 0
    },
    reducers: {
        totalAmount: (state,action) => {
            state.value = action.payload
        }
    }
});

export const {totalAmount} = amountSlice.actions;
export default amountSlice.reducer;