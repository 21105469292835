import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import styled, {keyframes } from "styled-components";
import "swiper/css";
import SlideImage1 from '../images/1.jpg';
import SlideImage2 from '../images/2.jpg';
import SlideImage3 from '../images/3.jpg';
import SlideImage4 from '../images/4.jpg';
import ImageLoad from "../utils/ImageLoad";

const SlideDiv = styled.div`
     padding: 0 20px;
     position: relative;
  `
  const sweep = keyframes`
    from {
        transform: translateX(60%);
        }
    to {
        transform: translateX(-100%);   
    }
  `
  const SlideText = styled.div`
    position: absolute;
    background-color: #0003;
    bottom: 0;
    left: 20px;
    right: 20px;
    z-index: 2;
    border-radius: 0 0 20px 20px;
  `

  const Para = styled.p`
        animation-name: ${sweep};
        animation-duration: 15s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        inline-size: max-content;
        margin-bottom: 0;
        padding: 8px;
        user-select: none;
        font-size: 14px;
        cursor: pointer;

        &:hover {
          animation-play-state: paused;
        }
  `

  const Img = styled.img`
     height: 190px;
     border-radius: 20px;
     cursor: pointer;
     width: 100%;
     object-fit: cover;
  `
  const imgStyle = {
      height: '190px',
      borderRadius: "20px",
      cursor: "pointer",
      width: "100%",
      objectFit: "cover"
  }

const BannerSlide = () => {
  
  return (
    <SlideDiv>
        <Swiper className="mySwiper" 
        autoplay={{
            delay: 2500,
            disableOnInteraction: false,
        }}
        spaceBetween={10}
        loop={true}
        modules={[Autoplay]}
        >
            <SwiperSlide>
               <ImageLoad placeholderSrc = {SlideImage1} style={imgStyle} alt="slide-image" src = {SlideImage1} />
            </SwiperSlide>
            <SwiperSlide>
               <ImageLoad placeholderSrc = {SlideImage2} style={imgStyle} alt="slide-image" src = {SlideImage2} />
            </SwiperSlide>
            <SwiperSlide>
               <ImageLoad placeholderSrc = {SlideImage3} style={imgStyle} alt="slide-image" src = {SlideImage3} />
            </SwiperSlide>
            <SwiperSlide>
               <ImageLoad placeholderSrc = {SlideImage4} style={imgStyle} alt="slide-image" src = {SlideImage4} />
            </SwiperSlide>
        </Swiper>
        <SlideText>
            <div style={{width: "93%",overflow: "hidden",margin: "0 auto"}}>
               <Para>မြန်မာနိုင်ငံအနှံ့ ကိုယ်စားလှယ်အလိုရှိသည်။ အရေအတွက်ကန့်သတ်ထားပါသည်။ စိတ်ဝင်စားသောမိတ်ဆွေများ ဖုန်းကိုဆက်သွယ်လိုက်ပါ။ 09500600700</Para>
            </div>
        </SlideText>
    </SlideDiv>
  )
}

export default BannerSlide