import { createSlice } from "@reduxjs/toolkit";

const parmuNumbersSlice = createSlice({
    name: "parmuNumbers",
    initialState: {
        value: []
    },
    reducers: {
        addNum: (state,action) => {
            state.value = action.payload;
        },
        cleanNum: state => {
            state.value = [];
        }
    }
});

export const {addNum,cleanNum} = parmuNumbersSlice.actions;
export default parmuNumbersSlice.reducer;