import React,{useEffect, useState} from 'react'
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { totalValue } from '../store/totalAmountSlice';

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border-radius: 10px;

  td, th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    font-size: 14px;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: ${props => props.theme.secondary};
    color: white;
  }

`
const ParmuNumbersTable = () => {
  const permuArrObj = useSelector(state => state.parmuNum.value);
  const dispatch = useDispatch();
  const total = useSelector(state => state.total.value);
  
  useEffect(()=>{
    if(permuArrObj.length > 0){
        const sumWithInitial = permuArrObj.reduce(
        (previousValue, currentValue) => Number(previousValue) + Number(currentValue.amount),total
        );
        dispatch(totalValue(sumWithInitial));
    }
  },[permuArrObj]);
  return (
    <Table>
        <thead>
            <tr>
                <th>နံပါတ်</th>
                <th>ထိုးငွေ</th>
            </tr>
        </thead>
        <tbody>
            {
                permuArrObj.length > 0 && permuArrObj.map((num,i) => {
                    return  <tr key = {i}>
                                <td>{num.number}</td>
                                <td>{num.amount}</td>
                            </tr>
                            
                })
            }
        </tbody>
        <tfoot>
            <tr>
                <th>စုစုပေါင်း</th>
                <th>{total}</th>
            </tr>
        </tfoot>
    </Table>
  )
}

export default ParmuNumbersTable