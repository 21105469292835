import React from 'react';
import {Routes,Route} from "react-router-dom";
import styled from "styled-components";
import Main from './components/Main';
import Login from './components/Login';
import NotFound from './utils/NotFound';
import RouteGuard from './utils/RouteGuard';
import Twod from './components/Twod';
import NumberList from './components/NumberList';
import Total from './components/Total';
import TimeList from './components/TimeList';
import ParmuNumber from './components/ParmuNumber';
import Threed from './components/Threed';
import ThreedTotal from './components/ThreedTotal';
import Close from './components/Close';
import Voucher from './components/Voucher';
import History from './components/History';
import Wallet from './components/Wallet';
import Profile from './components/Profile';
import Changepassword from './components/Changepassword';
import Winner from './components/Winner';
import BetsDetail from './components/BetsDetail';
import Lottery from './components/Lottery';
import WalletTopup from './components/WalletTopup';
import WalletWithdraw from './components/WalletWidthdraw';
import TransactionHistory from './components/TransactionHistory';
import TopupWidthdrawHistory from './components/TopupWidthdrawHistory';

const Container = styled.div`
      max-width: 480px;
      min-height: 100vh;
      opacity: 1;
      background-color: #003440;
      background-attachment: fixed;
      margin: 0 auto;
      position: relative;
      padding-bottom: 70px;
  `
  
function App() {
  return (
     <Container>
        <Routes>
           <Route path="/login" exact element={<Login />} />
           <Route path="/" exact element={
               <RouteGuard>
                  <Main />
               </RouteGuard>} 
            />
            {/* -------------TwoD ------------ */}
           <Route path="/wallet" exact element={
                <RouteGuard>
                   <Wallet/>
                </RouteGuard>
           } />
           <Route path="/wallet/topup" exact element={
                <RouteGuard>
                   <WalletTopup/>
                </RouteGuard>
           } />
           <Route path="/wallet/histories" exact element={
                <RouteGuard>
                   <TopupWidthdrawHistory/>
                </RouteGuard>
           } />
           <Route path="/wallet/widthdraw" exact element={
                <RouteGuard>
                   <WalletWithdraw/>
                </RouteGuard>
           } />
           <Route path="/profile" exact element={
               <RouteGuard>
                  <Profile />
               </RouteGuard>
           } />

           <Route path="/transaction-histories" exact element={
               <RouteGuard>
                  <TransactionHistory />
               </RouteGuard>
           } />
         
           <Route path="/twod" exact element={
               <RouteGuard>
                  <Twod />
               </RouteGuard>
           } />
           <Route path="/twod/voucher/:id" exact element={
               <RouteGuard>
                  <Voucher type = "2D"/>
               </RouteGuard>
           } />
           <Route path="/twod/voucher/:id/detail/:id" exact element={
               <RouteGuard>
                  <BetsDetail type = "2D"/>
               </RouteGuard>
           } />
           <Route path="/twod/history/:id" exact element={
               <RouteGuard>
                  <History type = "2D"/>
               </RouteGuard>
           } />
           <Route path="/twod/history/:id/detail/:id" exact element={
               <RouteGuard>
                  <BetsDetail type = "2D"/>
               </RouteGuard>
           } />
           <Route path="/twod/lottery-winner/:id" exact element={
               <RouteGuard>
                  <Winner type="2D" />
               </RouteGuard>
           } />
           <Route path="/twod/during-the-lottery/:id" exact element={
               <RouteGuard>
                  <Lottery type="2D" game="2d" />
               </RouteGuard>
           } />
           <Route path="/twod/time" exact element={
               <RouteGuard>
                  <TimeList type = "2D" filt = "2d"/>
               </RouteGuard>
           } />
           <Route path="/twod/time/number-list/:id" exact element={
               <RouteGuard>
                   <NumberList />
               </RouteGuard>
           }/>
           <Route path="/twod/time/number-list/:id/parmu" exact element={
               <RouteGuard>
                  <ParmuNumber />
               </RouteGuard>
           }/>
           <Route path="/twod/time/number-list/:id/total" exact element={
               <RouteGuard>
                  <Total />
               </RouteGuard>
           }/>
           <Route path="/twod/time/number-list/:id/parmu/total" exact element={
               <RouteGuard>
                  <Total />
               </RouteGuard>
           }/>

           {/* -------------ThreeD ------------ */}
           <Route path="/threed/time" exact element={
              <RouteGuard>
                 <TimeList type = "3D" filt = "3d" />
              </RouteGuard>
           } />
           <Route path="/threed/time/number-list/:id" exact element={
              <RouteGuard>
                 <Threed />
              </RouteGuard>
           } />
           <Route path="/threed/history/:id" exact element={
              <RouteGuard>
                 <History type = "3D" />
              </RouteGuard>
           } />
           <Route path="/threed/voucher/:id" exact element={
               <RouteGuard>
                  <Voucher type = "3D" />
               </RouteGuard>
           } />
           <Route path="/threed/voucher/:id/detail/:id" exact element={
               <RouteGuard>
                  <BetsDetail type = "3D" />
               </RouteGuard>
           } />
           <Route path="/threed/lottery-winner/:id" exact element={
               <RouteGuard>
                  <Winner type="3D" />
               </RouteGuard>
           } />
           <Route path="/threed/during-the-lottery/:id" exact element={
               <RouteGuard>
                  <Lottery type="3D" game="3d" />
               </RouteGuard>
           } />
           <Route path="/threed/time/number-list/:id/total" exact element={
               <RouteGuard>
                  <ThreedTotal />
               </RouteGuard>
           } />
           
           <Route path="/profile/change-password" exact element={
               <RouteGuard>
                  <Changepassword />
               </RouteGuard>
           } />
           <Route path="/profile/transaction-status/:id" exact element={
               <RouteGuard>
                  <BetsDetail type = "ထီ" />
               </RouteGuard>
           } />
           <Route path="/close" exact element={<Close />} />
           <Route path="*" element={<NotFound />} />
        </Routes>
     </Container>
  );
}

export default App;
