import React from 'react'
import styled from 'styled-components'
import ParmuNumbersTable from './ParmuNumbersTable'

const NumbersDiv = styled.div`
    padding: 20px 20px 70px 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`

const ParmuNumbersContainer = () => {
  return (
    <NumbersDiv>
          <ParmuNumbersTable />
    </NumbersDiv>
  )
}

export default ParmuNumbersContainer