import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { getMethodToken } from '../Apis/Apis';
import Loading from '../utils/Loading';
import HeaderNav from './HeaderNav';
import styled from 'styled-components';
import EmptyRecord from '../utils/EmptyRecord';

const Table = styled.table`
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 130%;

  td, th {
    border: 1px solid #ddd;
    padding: 8px;
    color: white;
    text-align: center;
    font-size: 13px;
  }

  tr:hover {background-color: #00000036;cursor: pointer;}

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: rgb(1, 87, 106);
  }
`

const Tbody = styled.tbody`
    // display: block;
    // max-height: 45vh;
    // overflow: hidden auto;
`

const TimeH1 = styled.h1`
    margin-bottom: 0;
    padding: 20px 20px 0 20px;
    font-size: 17px;
    text-align: center;
    display:flex;
    align-items: center;
    justify-content: space-between;
`

const BetsDetail = ({type}) => {
  const [loading,setLoading] = useState(true);
  const [data,setData] = useState([]);
  const navigate = useNavigate();
  const [length,setLength] = useState(true);
  const [timeRes,setTimeRes] = useState("--:--");
  const [date,setDate] = useState("");
  const [betAmount, setBetAmount] = useState(0);
  const [commission,setCommission] = useState(0);
  const [removeCommission,setRemoveCommission] = useState(0);
  let id = useParams();
  useEffect(()=>{
     let token = localStorage.getItem("token");
     if(token){
        const fetchData = async () => {
            const response = await getMethodToken(`/bets/${id.id}`,token);
            const resRounds = await getMethodToken(`/rounds`,token);
            if(resRounds){
              if(resRounds.message === "Unauthenticated."){
                localStorage.removeItem("token");
                navigate('/login');
                console.clear();
              }else{
                 if(response){
                    if(response.round_id){
                      resRounds.data.map(async el => {
                          if(el.id == response.round_id){
                            setTimeRes(timeConvert(el.name))
                          };
                      });
                    }
                 }
              };
            };

            if(response){
              if(response.message === "Unauthenticated."){
                localStorage.removeItem("token");
                navigate('/login');
                console.clear();
              }else{
                  if(response.date){
                    setDate(response.date);
                  };
                  if(response.total_bet_amount){
                      setBetAmount(response.total_bet_amount);
                  };
                  if(response.commission){
                      setCommission(response.commission);
                  };
                  
                  if(response.betNumbers){
                      let tempCom = [];
                      response.betNumbers.map(el => {
                        tempCom.push(el.amount - el.commission)
                      });
            
                      let totalRemoveComm = tempCom.reduce(function (accumulator, curValue) {
                        return accumulator + curValue
                      }, 0);
                      setRemoveCommission(totalRemoveComm);
                  };

                  setLoading(false);
                  setData(response);
                  if(response.betNumbers.length >0){
                      setLength(true);
                  }else{
                      setLength(false);
                  }
              };
            }
        }
        fetchData();
     };
  },[]);  

  function timeConvert (time) {
      time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
      if (time.length > 1) { 
      time = time.slice (1); 
      time[5] = +time[0] < 12 ? ' AM ' : ' PM ';
      time[0] = +time[0] % 12 || 12;
      };
      time.splice(3,1);
      return time.join ('');
  };

  const chunk = num => {
      let str = num.toString().split('.');
      if (str[0].length >= 4) {
          str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
      }
      if (str[1] && str[1].length >= 5) {
          str[1] = str[1].replace(/(\d{3})/g, '$1 ');
      }
      return str.join('.');
  };

  return (
    <>
      <HeaderNav type = {type} />
      <TimeH1>
        <span>{timeRes}</span>
        <span>{date}</span>
      </TimeH1>
      <div style={{padding: "20px"}}> 
        {length ? 
           <div style={{overflowX:'auto'}}>
           <Table>
                <thead>
                    <tr>
                        <th>No</th>
                        <th>နံပါတ်</th>
                        <th>အချိန်</th>
                        <th>ရက်ဆွဲ</th>
                        <th>ငွေပမာဏ</th>
                        <th>ကော်မရှင်</th>
                        <th>ကော်မရှင်နှုတ်ပြီး</th>
                        
                    </tr>
                </thead>
                <Tbody>
                      {
                          data.betNumbers && data.betNumbers.map((item,index) => 
                          <tr key={index}>
                              <td>{index + 1}.</td>
                              <td>{item.number}</td>
                              <td>{data.time}</td>
                              <td>{data.date}</td>
                              <td>{item.amount ? chunk(String(item.amount)) : 0}</td>
                              <td>{item.commission ? chunk(String(item.commission)) : 0}</td>
                              <td>{item.amount-item.commission ? chunk(String(item.amount-item.commission)) : 0}</td>
                          </tr>
                          )
                      }
                </Tbody>
                <tfoot>
                    <tr>
                        <td style={{color:"#fde34e"}} colSpan={4}>စုစုပေါင်းငွေ</td>
                        <td style={{color:"#fde34e"}}>{betAmount ? chunk(String(betAmount)) : 0}</td>
                        <td style={{color:"#fde34e"}}>{commission ? chunk(String(commission)) : 0}</td>
                        <td style={{color:"#fde34e"}}>{removeCommission ? chunk(String(removeCommission)) : 0}</td>
                    </tr>
                </tfoot>
           </Table>
       </div>:
       <EmptyRecord message = "No found" /> 
        }
      </div>
      {
        createPortal( loading && <Loading />, document.getElementById("portal"))
      }
    </>
  )
}

export default BetsDetail
