import { createSlice } from "@reduxjs/toolkit";
const twodSetValueSlice = createSlice({
    name: "setValue",
    initialState: {
        value: {}
    },
    reducers: {
        addSetValue : (state,action) => {
            state.value = action.payload;
        },
        cleanSetValue: (state) => {
            state.value = {}
        }
    }
});

export const {addSetValue,cleanSetValue} = twodSetValueSlice.actions;
export default twodSetValueSlice.reducer;