import React from 'react';
import {NavLink} from "react-router-dom";
import styled from 'styled-components';
import { FiHome, FiUser, FiCreditCard } from "react-icons/fi";

const NavDiv = styled.div`
   background-color: #01576a;
   position: fixed;
   bottom: 0;
   width: 480px;
   left: 50%;
   margin-left: -240px;
   z-index: 999;

   @media (min-width: 421px) and (max-width: 460px) {
    width: 460px;
    left: 50%;
    margin-left: -230px;
  }

  @media (min-width: 401px) and (max-width: 420px) {
    width: 420px;
    left: 50%;
    margin-left: -210px;
  }

  @media (min-width: 351px) and (max-width: 400px) {
    width: 396px;
    left: 50%;
    margin-left: -198px;
  }

  @media (min-width: 340px) and (max-width: 350px) {
    width: 350px;
    left: 50%;
    margin-left: -175px;
  }

  @media (min-width: 320px) and (max-width: 339px) {
    width: 332px;
    left: 50%;
    margin-left: -166px;
  }
`
const Ul = styled.ul`
   display: flex;
   justify-content: space-around;
   align-items: center;
`
const IconDiv = styled.div`
   padding: 10px 0;
   display: flex;
   flex-direction: column;
   align-items: center;

   span {
      font-size: 12px;
      margin-top: 6px;
   }
`
const activeStyle = {
    color: "#fdd719",
    fontWeight: "bold",
    transition: "0.1s"
};
const inactiveStyle = {
    color: "rgb(181 181 181)"
};
const listWidth = {
    width: "20%"
}
const Nav = () => {
  return (
    <NavDiv>
        <Ul>
            <li style = {listWidth}>
                <NavLink  to="/" style={({ isActive }) =>isActive ? activeStyle : inactiveStyle}>
                    <IconDiv>
                       <FiHome size="24px" />
                       <span>ပင်မ</span>
                    </IconDiv>
                </NavLink >
            </li>
            <li style = {listWidth}>
                <NavLink  to="/wallet" style={({ isActive }) =>isActive ? activeStyle : inactiveStyle}>
                    <IconDiv>
                       <FiCreditCard size="24px" />
                       <span>ပိုက်ဆံအိတ်</span>
                    </IconDiv>
                </NavLink >
            </li>
            <li style = {listWidth}>
                <NavLink  to="/profile" style={({ isActive }) =>isActive ? activeStyle : inactiveStyle}>
                    <IconDiv>
                        <FiUser size="24px"  />
                        <span>ကျွန်ုပ်</span>
                    </IconDiv>
                </NavLink >
            </li>
        </Ul>
    </NavDiv>
  )
}

export default Nav;