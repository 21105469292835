import React from 'react';
import {NavLink} from "react-router-dom";
import styled from 'styled-components';
import { GiReceiveMoney,GiPayMoney,GiMoneyStack } from "react-icons/gi";


const RecordDiv = styled.div`
   padding: 0px 20px 20px 20px;
`
const HistoryCard = styled.div`
   background-color: #ffffff36;
   border-radius: 10px;
`
const Ul = styled.ul`
   display: flex;
   justify-content: space-around;
   align-items: center;
`
const IconDiv = styled.div`
   padding: 10px 0;
   display: flex;
   flex-direction: column;
   align-items: center;

   span {
      font-size: 12px;
      margin-top: 6px;
   }
`
const listWidth = {
    width: "25%"
}

const TopupWidthdrawNav = ({}) => {
  return (
    <RecordDiv>
        <HistoryCard>
            <Ul>
                <li style = {listWidth}>
                    <NavLink to={`/wallet/topup`} style = {{color:"rgb(221 221 221)"}}>
                        <IconDiv>
                        <GiPayMoney size="24px" />
                        <span>ငွေသွင်း</span>
                        </IconDiv>
                    </NavLink >
                </li>
                <li style = {listWidth}>
                    <NavLink to={`/wallet/widthdraw`} style = {{color:"rgb(221 221 221)"}}>
                        <IconDiv>
                        <GiReceiveMoney size="24px" />
                        <span>ငွေထုတ်</span>
                        </IconDiv>
                    </NavLink >
                </li>
                <li style = {listWidth}>
                    <NavLink to={`/wallet/histories`} style = {{color:"rgb(221 221 221)"}}>
                        <IconDiv>
                        <GiMoneyStack size="24px" />
                        <span>မှတ်တမ်း</span>
                        </IconDiv>
                    </NavLink >
                </li>
            </Ul>
        </HistoryCard>
    </RecordDiv>
  )
}

export default TopupWidthdrawNav;