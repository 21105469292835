import React from 'react';
import {Link} from "react-router-dom";
import styled from 'styled-components';
import ImageLoad from '../utils/ImageLoad';
import load from '../images/1Hong_Kong.png'

const CardDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
`

const SpanText = styled.span`
    font-weight: bold;
    color: #fdd719;
    text-shadow: 1px 1px 1px #707070;
    letter-spacing: 1px;
`

const SingleApp = ({name,image,alt,path}) => {
  return (
    <div className='col-6'>
        <Link to= {path}>
            <CardDiv>
                <ImageLoad placeholderSrc = {image} src = {image} alt={alt} width="100%" />
                <SpanText>{name}</SpanText>
            </CardDiv>
        </Link>
    </div>
  )
}

export default SingleApp;