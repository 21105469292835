import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router} from "react-router-dom";
import {Provider} from "react-redux";
import { store } from './store/store';
import {ThemeProvider} from 'styled-components';
import GlobalStyle from './styles/globalStyles';
import App from './App';
import './index.css';

const theme = {
  primary: "#003440 !important",
  secondary: "#01576a !important",
  subSecondary: "#00FF00 !important",
  fontColor: "#fde34e !important"
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <ThemeProvider theme = {theme}>
    <GlobalStyle />
      <Provider store = {store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </ThemeProvider>
  // </React.StrictMode>
);
