import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {FiTrash2,FiEdit} from 'react-icons/fi';
import Button from "../utils/Button";
import { createPortal } from 'react-dom';
import Loading from '../utils/Loading';
import SubmitModal from '../utils/SubmitModal';
import { getMethodToken, postMethodRaw } from '../Apis/Apis';
import { cleanNums } from '../store/threedNumberSlice';
import {totalAmount} from '../store/amountSlice';
import {cleanthreedLists} from '../store/threedNumberList';
import {useNavigate} from 'react-router-dom';


const ContainerDiv = styled.form`
    padding: 20px
`
const Table = styled.table`
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
    thead, tr, th, td {
        border: 1px solid #9d9d9d;
    }
    th, td {
        padding: 10px;

        button {
            border: none;
            padding: 0.375rem 0.75rem;
            border: 1px solid transparent;
            border-radius: 50%;
            font-size: 16px;
            margin-left: 10px;

            &:focus {
                outline: 0;
                box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
            }
        }
    }
    td {
        color: #FFF;
        font-size: 15px;
    }
    thead, tbody tr, tfoot {
        display: table;
        width: 100%;
        table-layout: fixed;
    }
    thead {
        -webkit-box-shadow: #00000063 0px 5px 5px;;
         box-shadow: #00000063 0px 5px 5px;;
    }
`

const Tbody = styled.tbody`
    display: block;
    max-height: 45vh;
    overflow: hidden auto;
`
const MoneyP = styled.p`
    color: #d4e2ff;
`

const ThreedTotalTable = () => {
    const [loading,setLoading] = useState(true);
    const [title,setTitle] = useState("");
    const [openBox,setOpenBox] = useState(false);
    const [message,setMessage] = useState("");
    const [fColor,setFcolor] = useState("");
    const [currentBalance,setCurrentBalance] = useState(0);
    const [timePath,setTimePath] = useState(false);
    const dispatch = useDispatch();
    const total = useSelector(state => state.totallength.value);
    const threednumberList = useSelector(state => state.threedList.value);
    const submitNumber = useSelector(state => state.submitNumber.value);
    const navigate = useNavigate();
    
    let temp = [];
    let allTotal = 0;
    
    if(threednumberList.length > 0){
        threednumberList.map(el => {
            temp.push({number: el, amount: total});
        })
    };

    if(temp.length > 0){
        allTotal = temp.reduce(function (accumulator, curValue) {
            return Number(accumulator) + Number(curValue.amount)
          },0);
    }

    
    useEffect(() =>{
        let token = localStorage.getItem("token");
        const fetchData = async () => {
            const result = await getMethodToken("/user",token);
            if(result){
                if(result.message === "Unauthenticated."){
                    localStorage.removeItem("token");
                    navigate('/login');
                    console.clear();
                }else{
                    setCurrentBalance(result.wallet.balance);
                    setLoading(false);
                };
            };
        };
        fetchData();
    },[]);
    const submitResultHandler = async (e) => {
        e.preventDefault();
        let obj = {
            ...submitNumber,
            numbers: temp
        };
        setLoading(true);

        if(currentBalance >= total){
            let token = localStorage.getItem("token");
            if(token){
                const response = await postMethodRaw('/bets',obj,token);
                if(response.errors){
                    setTitle(response.message);
                    setMessage(response.message);
                    setFcolor("red");
                    setLoading(false);
                    setOpenBox(true);
                    setTimePath(false);
                }else{
                    setTitle("Success");
                    setFcolor("green");
                    setMessage("Success");
                    setTimePath(true);
                    temp = [];
                    obj = {};
                    allTotal = 0;
                    setLoading(false);
                    dispatch(cleanNums());
                    dispatch(totalAmount(0));
                    dispatch(cleanthreedLists());
                    setOpenBox(true);
                };
            }
        }else{
            setTitle("Error");
            setMessage("Current value is not enough");
            setFcolor("red");
            setLoading(false);
            setOpenBox(true);
            setTimePath(false);
        };
    };    
    
    const chunk = num => {
        let str = num.toString().split('.');
        if (str[0].length >= 4) {
            str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        }
        if (str[1] && str[1].length >= 5) {
            str[1] = str[1].replace(/(\d{3})/g, '$1 ');
        }
        return str.join('.');
    };
  return (
    <>
        <ContainerDiv onSubmit={submitResultHandler}>
            <Table>
                <thead>
                    <tr>
                        <th width="60px" style={{textAlign: 'right'}}>စဉ်</th>
                        <th style={{textAlign: 'center'}}>ကဏန်း</th>
                        <th style={{textAlign: 'right'}}>ငွေပမာဏ</th>
                    </tr>
                </thead>
                <Tbody>
                    {
                        threednumberList.length > 0 && 
                        threednumberList.map((el,index) => 
                            <tr key={el}>
                                <td width="60px" style={{textAlign: 'right'}}>{index + 1}</td>
                                <td style={{textAlign: 'center'}}>{el}</td>
                                <td style={{textAlign: 'right'}}>
                                    {total ? chunk(String(total)) : 0}
                                </td>
                            </tr>)
                    }
                </Tbody>
                <tfoot>
                        <tr>
                            <td width="60px" style={{textAlign: 'right'}}>#</td>
                            <td style={{color:"#fde34e",textAlign: 'center'}}>ထီထိုးငွေ</td>
                            <td style={{color:"#fde34e",textAlign: 'right'}}>{allTotal ? chunk(String(allTotal)) : 0}</td>
                        </tr>
                </tfoot>
            </Table>
            <div>
                <MoneyP>လက်ကျန်ငွေ - {chunk(String(currentBalance))}</MoneyP>
                <Button type="submit" title = "ထိုးမည်" />
            </div>
        </ContainerDiv>
        {
            createPortal( loading && <Loading />, document.getElementById("portal"))
        }
        {
            createPortal( openBox && <SubmitModal title ={title} message = {message} close ={setOpenBox} timePathPoint = {timePath ? "/threed/time": null} color = {fColor} />, document.getElementById("portal"))
        }
    </>
  )
}

export default ThreedTotalTable
