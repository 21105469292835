import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from "react-router-dom";
import styled from 'styled-components';
import {FiArrowLeft} from 'react-icons/fi';
import Logo from "../images/logo/elite.svg";
import { useDispatch } from 'react-redux';
import { cleanValue } from '../store/towdNumbersList';
import { cleanRValue } from '../store/twodRNumberList';
import { actionAmountStatus } from '../store/activeAmountDisable';
import {actionStatus} from '../store/activeRdisableAll';
import { cleanNum } from '../store/parmuNumbersSlice';
import { cleanTotal } from '../store/totalAmountSlice';
import { cleanNums } from '../store/threedNumberSlice';
import { cleanthreedLists } from '../store/threedNumberList';
import { totalAmount } from '../store/amountSlice';
import { cleanSubmitValue } from '../store/submitNumberSlice';
import { getMethodToken } from '../Apis/Apis';
import moment from 'moment-timezone';


const NavDiv = styled.div`
   background-color: #01576a;
   position: sticky;
   top: 0;
   left: 0;
   right: 0;
   z-index: 10;
`
const NavUl = styled.ul`
   display: flex;
   align-items:center;
   justify-content: space-between;
   padding: 20px;
`
const ResultDiv = styled.div`
   display: flex;
   align-items:center;
   justify-content: space-between;
   padding: 0 20px 20px 20px;

   h3 {
    margin-bottom: 0;
    font-size: 30px;
   }
   span {
     font-size: 13px;
     color: #FFF;
   }
`

const BackBtn = styled.button`
    border: none;
    border-right: 1px solid #ffffff6b;
    padding-right: 20px;
    background-color: transparent;
    cursor: pointer;
`
const LogoFlex = styled.div`
   display: flex;
   align-items: center;
` 
const PageTitle = styled.h5`
   font-size: 18px;
   margin-bottom: 0;
   margin-left: 10px;
   color: #fffc;
   user-select: none;
   text-align: center;
`

const HeaderTitle = ({message}) => {
  const [timeRes,setTimeRes] = useState("--:--");
  const [result, setResult] = useState()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let id = useParams();
  let currentDate = moment().tz("Asia/Yangon").format("YYYY-MM-DD");


  useEffect(()=> {
    let token = localStorage.getItem("token");
        if(token){
            const fetchData = async () => {
                const response = await getMethodToken(`/rounds`,token);
                if(response){
                  if(response.message === "Unauthenticated."){
                    localStorage.removeItem("token");
                    navigate('/login');
                    console.clear();
                  }else{
                    response.data.map(async el => {
                        if(el.id == id.id){
                            setTimeRes(timeConvert(el.end_time));
                            setResult(el.result);
                        };
                    });
                  }
                }
            };
            fetchData();
    };
},[currentDate]);

function timeConvert (time) {
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) { 
    time = time.slice (1); 
    time[5] = +time[0] < 12 ? ' AM ' : ' PM ';
    time[0] = +time[0] % 12 || 12;
    };
    time.splice(3,1);
    return time.join ('');
};

  const backNavigate = () => {
    dispatch(cleanValue());
    dispatch(cleanTotal());
    dispatch(cleanRValue());
    dispatch(cleanNums());
    dispatch(cleanthreedLists());
    dispatch(actionAmountStatus(false));
    dispatch(totalAmount(0))
    dispatch(actionStatus(false));
    dispatch(cleanNum());
    dispatch(cleanSubmitValue());
    navigate(-1);
  };
  
  return (
    <NavDiv>
        <NavUl>
            <li>
                <BackBtn onClick={backNavigate}>
                    <FiArrowLeft size = "23px" color = "rgb(255 255 255 / 61%)" />
                </BackBtn>
            </li>
            <li>
                <LogoFlex>
                    <PageTitle>{message}</PageTitle>
                </LogoFlex>
            </li>
        </NavUl>
        <ResultDiv>
            <span>{currentDate} {timeRes}</span>
            <h3>{result? result : "--"}</h3>
        </ResultDiv>
    </NavDiv>
  )
}

export default HeaderTitle