import React, { useEffect, useState } from "react";
import Header from "./Header";
import FooterNav from "./FooterNav";
import { createPortal } from "react-dom";
import Loading from "../utils/Loading";
import HeaderNav from "./HeaderNav";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Input from "../utils/Input";
import SubmitModal from '../utils/SubmitModal';
import { getMethodToken, postMethodRaw } from '../Apis/Apis';


const Container = styled.div`
  padding: 20px;
  color: #fff;
`;

const MyLabel = styled.div`
  padding: 10px 0 10px 0;
  font-size: 14px;
`;

const TitleDiv = styled.div`
  text-align: center;
  padding-bottom: 5px;
`;

const AuthBalanceDiv = styled.div`
  padding-top: 1rem;
  text-align: center;
`;

const BankCard = styled.div`
  background-color: #ffffff36;
  border-radius: 10px;

  hr{
    margin: 0;
  }
`;
const Ul = styled.ul`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: ${(props) => props.getwidth > 100 ? props.getwidth - 15 :`100`}%;

  li {
    cursor: pointer;
    width: 25%;
  }
`;
const PhNoDiv = styled.div`
  padding: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  p{
    margin: 0;
  }

  button{
    padding: 5px 10px 5px 10px;
    background:#fff;
    outline: none;
    border-radius: 5px;
    border: none;
  }
`;
const IconDiv = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    border-radius: 10px;
    ${(props) =>
      props.selected &&
      `border: 1px #FFCC00 solid;
      padding: 3px;`}
  }

  span {
    font-size: 12px;
    margin-top: 6px;
  }
`;

const MyInput = styled.input`
    width: 100%;
    padding: 10px 40px 10px 10px;
    outline: none;
    border-radius: 5px;
    border: none;
    font-size: 14px;
`

const AmountBtnContainer = styled.div`
    padding: 10px 0 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const MyAmountBtn = styled.button`
    width: 30%;
    padding: 10px 40px 10px 40px;
    outline: none;
    border-radius: 5px;
    border: none;
    font-size: 14px;
`

const SubmitBtn = styled.button`
    width: 100%;
    padding: 10px 40px 10px 40px;
    outline: none;
    border-radius: 5px;
    border: none;
    font-size: 14px;
    margin-top: 10px;
`

const WalletWidthdrawForm = () => {
  const [loading, setLoading] = useState(true);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [wallet, setWallet] = useState(0);
  const [amount, setAmount] = useState(null);
  const [account, setAccount] = useState(null);
  const [accountConfirmation, setAccountConfirmation] = useState(null);
  const [userNameError,setUserNameError] = useState("");
  const [userPassError,setUserPassError] = useState("");
  const navigate = useNavigate();
  const [banks, setBanks] = useState([]);
  const [password, setPassword] = useState(null);

  const handlePasswordChange = (e) =>{
    setPassword(e.target.value);
  }

  //model require state
  const [title,setTitle] = useState("");
  const [openBox,setOpenBox] = useState(false);
  const [timePath,setTimePath] = useState(false);
  const [message,setMessage] = useState("");
  const [fColor,setFcolor] = useState("");

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      const fetchData = async () => {
        const result = await getMethodToken("/user", token);
        if (result) {
          if (result.message === "Unauthenticated.") {
            localStorage.removeItem("token");
            navigate("/login");
            console.clear();
          } else {
            setWallet(result.wallet.balance);
            setLoading(false);
          }
        }

        const bank_response = await getMethodToken("/banks", token);
        if (bank_response) {
          if (bank_response.message === "Unauthenticated.") {
            // console.clear();
          } else {
            console.log(bank_response);
            setBanks(bank_response.data);
            setLoading(false);
          }
        }
      };
      fetchData();
    }
  }, []);

  const chunk = (num) => {
    let str = num.toString().split(".");
    if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, "$1 ");
    }
    return str.join(".");
  };

  const handleAmountInput = (e) =>{
    setAmount(e.target.value);
  }
  const handleAccountInput = (e) =>{
    setAccount(e.target.value);
  }
  const handleAccountConfirmationInput = (e) =>{
    setAccountConfirmation(e.target.value);
  }
  const handlePaymentSelect = (value) =>{
    // setSelectedPaymentAccount(value.account_number);
    setSelectedPayment(value);
  }

  const handleSubmit = async (e) =>{
    e.preventDefault();
    let obj = {
       'account' : account,
       'account_confirmation' : accountConfirmation,
       'bank' : selectedPayment.name,
       'amount' : amount,
      'password' : password,

    };
    setLoading(true);
    console.log(obj);
  
    let token = localStorage.getItem("token");
    
    if(token){
      const response = await postMethodRaw('/widthdraw',obj,token);
      if(response){
          if(response.errors){
              setTitle(response.message);
              setMessage(response.message);
              setFcolor("red");
              setLoading(false);
              setOpenBox(true);
              setTimePath(false);
          }else{
              setTitle("Success");
              setFcolor("green");
              setMessage("Success");
              setTimePath(true);
              obj = {};
              setLoading(false);
              // dispatch(cleanValue());
              // dispatch(cleanTotal());
              // dispatch(cleanRValue());
              // dispatch(actionAmountStatus(false));
              // dispatch(cleanSubmitValue());
              // dispatch(actionStatus(false));
              // dispatch(cleanNum());
              // dispatch(totalAmount(0));
              setOpenBox(true);
          };
      };
    };  
  }
 
  return (
    <>
      <Container>
        <TitleDiv>
          <h6>ငွေထုတ်</h6>
        </TitleDiv>

        {/* select payment section */}
        <MyLabel>ငွေထုတ်မည့်ဘဏ်ရွေးချယ်ပါ</MyLabel>
        <BankCard>
          <Ul getwidth={((banks.length)*25)}>
            {
              banks.map(bank=>(
                <li onClick={() => handlePaymentSelect(bank)} key={bank.id}>
                  <IconDiv selected={selectedPayment === bank ? true : false}>
                    <img src="https://file.thai2d3dgame.com/files/payment/kbzpay.png" />
                    <span>{bank.name}</span>
                  </IconDiv>
                </li>
              ))
            }
          </Ul>
        </BankCard>
        <AuthBalanceDiv>
          <p>လက်ကျန်ငွေ : {wallet ? chunk(String(wallet)) : 0}</p>
        </AuthBalanceDiv>
        <hr />

        <MyLabel>ထုတ်မည့်ပမာဏ</MyLabel>
        <MyInput type={'number'} placeholder="50000" value={amount} onChange={(e) => handleAmountInput(e)}/>

      <MyLabel>ငွေလက်ခံမည့် အကောင့်</MyLabel>
      <MyInput type={'text'} placeholder="09xxxxxxxxx" value={account} onChange={(e) => handleAccountInput(e)}/>
      
      <MyLabel>လက်ခံမည့် အကောင့် အတည်ပြုရန်</MyLabel>
      <MyInput type={'text'} placeholder="09xxxxxxxxx" value={accountConfirmation} onChange={(e) => handleAccountConfirmationInput(e)}/>
      
      <MyLabel>စကားဝှက်</MyLabel>
      <MyInput type={'password'} placeholder="" value={password} onChange={(e)=> handlePasswordChange(e)}/>
      <SubmitBtn onClick={(e) => handleSubmit(e)}>Submit</SubmitBtn>
      
      </Container>
      {createPortal(loading && <Loading />, document.getElementById("portal"))}
      {
          createPortal( openBox && <SubmitModal title ={title} message = {message} close ={setOpenBox} timePathPoint = {timePath ? "/wallet/histories": null} color = {fColor} />, document.getElementById("portal"))
      }
    </>
  );
};

export default WalletWidthdrawForm;
