import React, { useState } from 'react'
import { FiEyeOff,FiEye } from "react-icons/fi";
import styled from 'styled-components';

const PasswordInput = styled.input`
    width: 100%;
    padding: 10px 40px 10px 10px;
    outline: none;
    border-radius: 10px;
    border: none;
    font-size: 14px;
`

const ToggleIcon = styled.span`
    position: absolute;
    right: 15px;
    top: 38px;
    margin-bottom: 0 !important;
    font-size: 17px !important;
    color: #7c7c7c !important;
`

const Passwordtoggle = ({name,placeholder,refValue}) => {
    const [isVisible, setVisible] = useState(false);
    const toggle = () => {
        setVisible(!isVisible);
    };
  return (
    <>
        <PasswordInput type={!isVisible ? "password" : "text"} placeholder={placeholder} ref={refValue} name={name} required />
        <ToggleIcon className="icon" onClick={toggle}>
            {isVisible ? <FiEye /> : <FiEyeOff />}
        </ToggleIcon>
    </>
  )
}

export default Passwordtoggle
