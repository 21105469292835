import React, { useEffect, useState } from 'react'
import HeaderNav from './HeaderNav';
import styled from 'styled-components';
import { getMethodToken } from '../Apis/Apis';
import EmptyRecord from '../utils/EmptyRecord';
import { createPortal } from 'react-dom';
import Loading from '../utils/Loading';
import { useNavigate, useParams } from 'react-router-dom';

const ListVoucher = styled.li`
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 14px;
    &:last-child {
        margin-bottom: 0;
    }
`
const CardDiv = styled.div`
   padding: 18px;
   background-color: #FFF;
   border-radius: 10px;
   display:flex;
   align-items: flex-start;
   justify-content: space-between;
   color: #090b42f2;

   span {
    display: block;
   }
`

const Ul = styled.ul`
   overflow: hidden auto;
`

const Lottery = ({type,game}) => {
  const [loading, setLoading] = useState(true);
  const [lottery,setLottery] = useState([]);
  const [length,setLength] = useState(true);
  const navigate = useNavigate();
  let id = useParams();
  useEffect(()=>{
      let token = localStorage.getItem("token");
      if(token){
         const fetchData = async () => {
            let result = await getMethodToken(`/result-history?round_id=${id.id}&game_type=${game}`, token);
            if(result){
                if(result.message === "Unauthenticated."){
                    localStorage.removeItem("token");
                    navigate('/login');
                    console.clear();
                  }else{ 
                    setLottery(result.data);
                    setLoading(false);
                    if(result.data.length > 0){
                        setLength(true);
                    }else{
                        setLength(false);
                    }
                  };
            };
         }
         fetchData();
      }
  },[]);
  return (
    <>
    <HeaderNav type = {type} />
    <div style={{padding: "20px 20px 0 20px",overflowX: "auto", position: "relative"}}>
       {
        length ? 
        <Ul>
             {
                lottery.map((lott,index) => 
                <ListVoucher key={index}>
                    <CardDiv>
                       <div>
                            <span style={{marginBottom: "10px"}}>စဉ်</span>
                            <div>
                            <span>{index + 1}.</span>
                            </div>  
                        </div>
                        <div>
                            <span style={{marginBottom: "10px"}}>Set</span>
                            <div>
                            <span>{lott.set? lott.set : "--"}</span>
                            </div>
                        </div>
                        <div>
                            <span style={{marginBottom: "10px"}}>Value</span>
                            <div>
                            <span style={{textAlign: "center"}}>{lott.value? lott.value : "--"}</span>
                            </div>
                        </div>
                        <div>
                            <span style={{marginBottom: "10px"}}>2D</span>
                            <div>
                            <span style={{textAlign: "center",fontSize: '16px', fontWeight: 'bold'}}>{lott.result? lott.result: "--"}</span>
                            </div>
                        </div>
                        <div>
                            <span style={{marginBottom: "10px"}}>Date</span>
                            <div>
                            <span style={{textAlign: "center"}}>{lott.date}</span>
                            </div>
                        </div>
                    </CardDiv>
                </ListVoucher>  
                )
             }
        </Ul>:
        <EmptyRecord message="ထီပေါက်စဉ် မရှိပါ" />
       }
    </div>
    {
        createPortal( loading && <Loading />, document.getElementById("portal"))
    }
    </>
  )
}

export default Lottery
