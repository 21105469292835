import React from 'react';
import {useNavigate} from "react-router-dom";
import styled from 'styled-components';
import {FiArrowLeft} from 'react-icons/fi';
import Logo from "../images/logo/elite.svg";
import { useDispatch } from 'react-redux';
import { cleanValue } from '../store/towdNumbersList';
import { cleanRValue } from '../store/twodRNumberList';
import { actionAmountStatus } from '../store/activeAmountDisable';
import {actionStatus} from '../store/activeRdisableAll';
import { cleanNum } from '../store/parmuNumbersSlice';
import { cleanTotal } from '../store/totalAmountSlice';
import { cleanNums } from '../store/threedNumberSlice';
import { cleanthreedLists } from '../store/threedNumberList';
import { totalAmount } from '../store/amountSlice';
import { cleanSubmitValue } from '../store/submitNumberSlice';


const NavDiv = styled.div`
   background-color: #01576a;
   position: sticky;
   top: 0;
   left: 0;
   right: 0;
   z-index: 10;
`
const NavUl = styled.ul`
   display: flex;
   align-items:center;
   justify-content: space-between;
   padding: 20px 20px;
`

const BackBtn = styled.button`
    border: none;
    border-right: 1px solid #ffffff6b;
    padding-right: 20px;
    background-color: transparent;
    cursor: pointer;
`
const LogoFlex = styled.div`
   display: flex;
   align-items: center;
` 
const PageTitle = styled.h5`
   font-size: 15px;
   margin-bottom: 0;
   margin-left: 10px;
   color: #fffc;
   user-select: none;
`

const HeaderNav = ({type}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const backNavigate = () => {
    dispatch(cleanValue());
    dispatch(cleanTotal());
    dispatch(cleanRValue());
    dispatch(cleanNums());
    dispatch(cleanthreedLists());
    dispatch(actionAmountStatus(false));
    dispatch(totalAmount(0))
    dispatch(actionStatus(false));
    dispatch(cleanNum());
    dispatch(cleanSubmitValue());
    navigate(-1)
  }
  return (
    <NavDiv>
        <NavUl>
            <li>
                <BackBtn onClick={backNavigate}>
                    <FiArrowLeft size = "23px" color = "rgb(255 255 255 / 61%)" />
                </BackBtn>
            </li>
            <li>
                <LogoFlex>
                    <img src={Logo} alt = "Logo" width="70px" />
                    <PageTitle>{type} ထိုးမယ်</PageTitle>
                </LogoFlex>
            </li>
        </NavUl>
    </NavDiv>
  )
}

export default HeaderNav