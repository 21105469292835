import React,{useState} from 'react';
import styled from 'styled-components';

const InputGroup = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;

    label {
        position: absolute;
        pointer-events: none;
        transform: translate(0, 23px) scale(1);
        transform-origin: top left;
        transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        color: #6f81a5;
        font-size: 15px;
        line-height: 1;
        left: 16px;
    }
    &:focus-within label {
        transform: translate(0, 12px) scale(0.8);
        color: #0a53e4;
    }
    input {
        height: 60px;
        border-radius: 4px;
        border: none;
        padding: 24px 16px 4px 16px;
        font-size: 14px;
        line-height: 1;
        outline: none;
        box-shadow: none;
        transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }
    input:focus {
        box-shadow: 0 0 0 2px #79b1ff;
    }
`

const Input = ({type,label,setRef ,mb ,errorMsg,setErrorMsg}) => {
  const [value,setValue] = useState(false);
  const handleChange = (e) => {
      setErrorMsg("");
     if(e.target.value.length > 0){
        setValue(true);
     }else{
        setValue(false);
     };
  };
 
  return (
    <InputGroup style={{marginBottom: mb}}>
       <input type={type} onChange={handleChange} ref = {setRef} style = {{border: errorMsg.length > 0 ? "2px solid red": " ", marginBottom: "3px"}} />
       <span style={{color: "red", fontSize: "13px"}}>{errorMsg}</span>
       <label style= {{transform: value && "translate(0, 12px) scale(0.8)"}}>{label}</label>
    </InputGroup>
  )
}

export default Input;