import React,{useEffect, useRef,useState} from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getMethodToken } from '../Apis/Apis';
import { addNum,cleanNum } from '../store/parmuNumbersSlice';
import { totalValue } from '../store/totalAmountSlice';
import Loading from '../utils/Loading';

const TotalDiv = styled.div`
   display:flex;
   justify-content: space-between;
   align-items: flex-start;
   padding: 0 20px 20px 20px;
   background-color: ${props => props.theme.secondary};

   input {
        width: 100%;
        outline: none;
        border-radius: 10px;
        border: none;
        height: 100%;
        border-color: #c0c4cc;
        font-size: 15px;
        text-align: right;
        color: #606266;
        padding: 10px;
        margin-right: 15px;

        &[type=checkbox]{
            display:none;
        }
        @media (min-width: 370px) and (max-width: 400px) {
            font-size: 10px;
        }
        @media (min-width: 401px) and (max-width: 426px) {
            font-size: 13px;
        }
    }

    
`

const SubmitBtn = styled.button`
    padding: 10px;
    font-size: 15px;
    border: none;
    border-radius: 10px;
    background-image: -webkit-linear-gradient(92deg,#fdd164,#daa520);
    color: #4d2707;
    width: 100%;
    margin-left: 15px;
    @media (min-width: 370px) and (max-width: 400px) {
        font-size: 10px;
    }
    @media (min-width: 401px) and (max-width: 426px) {
        font-size: 13px;
    }
`

const CheckLabelApuu = styled.label`
    margin: auto;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    border: 1px solid #606266;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
    color: #606266;
    font-size: 15px;
    display: block;
    background-color: #FFF;

    @media (min-width: 370px) and (max-width: 400px) {
        font-size: 10px;
    }
    @media (min-width: 401px) and (max-width: 426px) {
        font-size: 13px;
    }
`

const InpCbx = styled.input`
    &:checked+label{
        stroke-dashoffset: 0;
        background-image: -webkit-linear-gradient(92deg,#fdd164,#daa520);
        color: #FFF;
    } 
`

const ParmuNumberForm = () => {
    const amountRef = useRef();
    const numberRef = useRef();
    const checkedHandRef = useRef();
    const dispatch = useDispatch();
    const [min,setMin] = useState(0);
    const [max,setMax] = useState(0);
    const [loading,setLoading] = useState(true);
    const [numberBtn, setNumberBtn] = useState([]);
    const navigate = useNavigate();
    let paramId = useParams();

    useEffect(()=>{
        let token = localStorage.getItem("token");
        if(token){
            const fetchData = async () => {
                const response = await getMethodToken(`/rounds?game_type=2D`,token);
                if(response){
                    if(response.message === "Unauthenticated."){
                        localStorage.removeItem("token");
                        navigate('/login');
                        console.clear();
                    }else{  
                        response.data.length > 0 && 
                        response.data.map(async el => {
                            if(el.id == paramId.id){
                                const result = await getMethodToken(`/settings?type=${el.type}`,token);
                                if(result.data){
                                    setMin(result.data[0].min_per_bet);
                                    setMax(result.data[0].max_per_bet);
                                }
                                setLoading(false);
                            }
                        })
                        setLoading(false);
                    };
                }

                const result = await getMethodToken(`/two_d_numbers?round_id=${paramId.id}`,token);
                if(result){
                    if(result.message === "Unauthenticated."){
                        localStorage.removeItem("token");
                        navigate('/login');
                        console.clear();
                    }else{
                        setNumberBtn(result.data);
                        setLoading(false);
                    };
                };
            };
            fetchData();
        }
    },[]);


    const submitHandler = e => {
        e.preventDefault();
        const number = numberRef.current.value;
        const amount = amountRef.current.value;
        const checked = checkedHandRef.current.checked;
        
        dispatch(totalValue(0));
        let tempNums = [];
        if(number.length > 2){
           if(number.length < 10){
              if(Number(amount) < min){
                dispatch(cleanNum());
                return alert(`Minium amount is ${min}`);
              };
              if(Number(amount)%min !== 0){
                dispatch(cleanNum());
                return;
              };
              if(Number(amount) > max){
                dispatch(cleanNum());
                return alert(`Maxium amount is ${max}`);
              }
            //   result here
            
            
            function pariNums(arr, n)
                {
                    for (let i = 0; i < n; i++) {
                        for (let j = 0; j < n; j++) {
                            let num = String(arr[i]) + String(arr[j]);
                            if(checked){
                                if(!tempNums.includes(num) && num[0] !== num[1]){
                                    tempNums.push(num); 
                                };
                            }else{
                                if(!tempNums.includes(num)){
                                    tempNums.push(num);  //<<-- condition here without a puuu number
                                };
                            }
                        }
                    };
                };

    
                
                let arr = number.split("");
                let n = arr.length;
                pariNums(arr, n);
                let tempArrObj = [];
                let closeTemp = [];
                let openTemp = [];
                let parmuNumTemp = [];

                tempNums.map(num => {
                    numberBtn.map(n => {
                        if(n.number == num){
                            parmuNumTemp.push(n);
                        }
                    });
                });
                parmuNumTemp.map(value => {
                    if(value.off || value.percentage == 100){
                        closeTemp.push(value);
                    }else{
                        openTemp.push(value);
                    }
                });
                if(closeTemp.length > 0){
                    closeTemp = closeTemp.map(cl => cl.number);
                    alert(closeTemp+" သည် ပိတ်ဂဏာန်းများဖြစ်သည်");
                }
                tempNums = openTemp.map(value => value.number);

                if(tempNums.length > 0){
                    tempNums.map(num => {
                        tempArrObj.push({number: num, amount: amount});
                    })
                };
                if(tempArrObj.length > 0){
                    dispatch(addNum(tempArrObj));
                };
           }else{
            alert("Maxinum number is 9");
           }
        }else{
            alert("Minium number is 3");
        };
    }
  return (
    <>
      <form onSubmit={submitHandler}>
        <TotalDiv>
            <input type="number" ref = {numberRef} placeholder='နံပါတ်'/>
            <input type="number" ref = {amountRef} placeholder={`min ${min}`} />
            <div style={{width: "100%"}}>
                <InpCbx id="cbx" type="checkbox" ref={checkedHandRef} />
                <CheckLabelApuu htmlFor='cbx'>
                    အပူးမပါ
                </CheckLabelApuu>
            </div>
            <SubmitBtn type="submit">ရွေးမည်</SubmitBtn>
        </TotalDiv>
    </form>
    {
        createPortal( loading && <Loading />, document.getElementById("portal"))
    }
    </>
  )
}

export default ParmuNumberForm