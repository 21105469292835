import React, { useEffect, useState } from 'react';
import { getMethodToken } from '../Apis/Apis';
import EmptyRecord from '../utils/EmptyRecord';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';


const ListVoucher = styled.li`
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 13px;
    &:last-child {
        margin-bottom: 0;
    }
`
const CardDiv = styled.div`
   padding: 18px;
   background-color: #FFF;
   border-radius: 10px;
   display:flex;
   align-items: flex-start;
   justify-content: space-between;
   color: #090b42f2;

   span {
    display: block;
   }
`

const Ul = styled.ul`
   overflow: hidden auto;
`

const WinnerLists = ({setLoading}) => {
  const [winner,setWinner] = useState([]);
  const [length,setLength] = useState(true);
  let paramsId = useParams();
  let currentDate = moment().tz("Asia/Yangon").format("YYYY-MM-DD");
  useEffect(()=>{
      let token = localStorage.getItem("token");
      if(token){
         const fetchData = async () => {
            let result = await getMethodToken(`/winners?round_id=${paramsId.id}&date=${currentDate}`, token);
            if(result){
                setLoading(false);
                if(result.data){
                    setWinner(result.data);
                    if(result.data.length > 0){
                        setLength(true);
                    }else{
                        setLength(false);
                    };
                };
            };
         }
         fetchData();
      }
  },[currentDate]);
  return (  
    <div style={{padding: "20px 20px 0 20px",overflowX: "auto", position: "relative"}}>
      {
        length ? 
        <Ul>
            {
                winner.length > 0 && winner.map((win,index) => 
                    <ListVoucher key = {index}>
                        <CardDiv>
                            <div>
                                <span style={{marginBottom: "10px"}}>စဉ်</span>
                                <div>
                                    <span>{index + 1}</span>
                                </div>
                            </div>
                            <div>
                                <span style={{marginBottom: "10px"}}>အမည်</span>
                                <div>
                                <span>{win.name}</span>
                                <span>{win.phone}</span>
                                </div>
                            </div>
                            <div>
                                <span style={{marginBottom: "10px"}}>ထီထိုးငွေ</span>
                                <div>
                                <span style={{textAlign: "center"}}>{win.amount}</span>
                                </div>
                            </div>
                            <div>
                                <span style={{marginBottom: "10px"}}>ထီပေါက်ငွေ</span>
                                <div>
                                <span style={{textAlign: "center"}}>{win.bingo}</span>
                                </div>
                            </div>
                        </CardDiv>
                    </ListVoucher>    
                )
            }
        </Ul>
        : <EmptyRecord message="အနိုင်ရသူမရှိပါ" />
      }
    </div>
  )
}

export default WinnerLists
